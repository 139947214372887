import React, {useEffect, useState , useContext} from "react";
import { Outlet, Link, useLocation  } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { BsPlusSquare } from "react-icons/bs";
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from "../sidebar/";
import { Modal , Badge } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { decrpt } from "../../../components/Slider/encryption";
import Loader from "../../../components/Slider/loader"
import Username from "../../context/profile";

function Address() {  
  
  const location = useLocation();
  const profileName = useContext(Username);
  const [openNewAddress, setOpenNewAddress] = useState(false);
  const [openEditAddress , setEditNewAddress] = useState(false);
  const [statusShow , setStatusShow ] = useState();
  const [ toastText,setToastText ] = useState('');
  const [loader , setLoader] = useState();
  const [pinLoader , setPinLoader] = useState(false);
  const [message , setMessage ] = useState();
  const [error , setError ] = useState();
  const [validationError , setValidationError ] = useState(false);
  const [address , setAddress ] = useState();
  const [newAddress , setNewAddress] = useState(false);
  const [editAddress , setEditAddress ] = useState();
  const [city , setCity] = useState();
  const [state , setState] = useState();
  // const [newValue , setNewValue] = useState();
  const [removeAddress , setRemoveAddress] = useState();


  // console.log(editAddress , 'newAddress');
  // console.log(profileName , 'Username Username');

  const addressList = async() => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/shipping/address/list`)
      .then((response) => {
        console.log(response.data , 'user details');
        setAddress(response.data.data);
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'errors use details');
      setLoader(false);
    }
  }

  const addAddress = async() => {
    setLoader(true);
    try{
      const data = {
        state_id      : newAddress.state,
        city_id       : newAddress.city,
        pin           : newAddress.pin,
        address       : newAddress.address,
        mobile        : newAddress.mobile,
        address_type  :  newAddress.address_type,
        land_mark     : newAddress.land_mark,
        street        : newAddress.street,
        alt_mobile    : newAddress.alt_mobile
      
      }
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/add/update` , data  )
      .then((response) => {
        // console.log(response.data , 'add address')
        address.push(response.data.data);
        setLoader(false);
        setOpenNewAddress(false);
      })
    }catch(err) {
      // console.log(err , 'err');
      setLoader(false);
      setOpenNewAddress(true)
      setValidationError(err.response.data.errors)
    }
  }

const stateFetch = async() => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/state/city/fetch` , {type: 1} ) 
      // console.log(response.data.data, 'state data');
      setState(response.data.data);
    }catch(err) {
        console.log(err.response.data , 'err')
    }
  }


  const handleDelete = (item) => {
    setRemoveAddress(item);
    setStatusShow(true);
    console.log(item , 'check item')
    // console.log(removeAddress , 'check item')
    // deleteAddress();
  }
  const handleEdit = (item) => {
    setEditNewAddress(true);
    setEditAddress(item);
    // console.log(editAddress , 'check item')
  }



  const handleState = (state)=> {
    setNewAddress({
      ...newAddress,
      state : state,
    })
    setEditAddress({
      ...editAddress,
      state : state
    })
    // console.log(newAddress , 'sdnksndksndks');
    cityFetch(state);
  }
 

  const cityFetch = async(state) => {
    // console.log(newAddress, 'state check in city');
    try {
      const data = {
        state_id : state,
        type : 2 
      }
      console.log(data , state , 'city fetch data')
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/state/city/fetch` , data )
      .then((response) => {
        setCity(response.data.data);
        // console.log(response.data.data, 'city data');
      })
    }catch(err) {
      console.log(err.response.data , 'err')
    }
  }

const pinCheck = async(pin) => {
  setPinLoader(true);
  try{
    const data = {
      pin : newAddress.pin
    }
    const response = await axios.post(`/check/pin` , data )
    .then((response) => {
      // console.log(response.data, 'pin check');
      setMessage(response.data.message);
      setError(false);
      setPinLoader(false);
    })
  }catch(error){
    // console.log(error , 'pin chedck erro')
    setError(error.response.data.errors);
    // setMessage(false);
    setMessage(error.response.data.message);

  }
}
const pinCheckEdit = async(pin) => {
  try{
    const data = {
      pin : editAddress.pin
    }
    const response = await axios.post(`/check/pin` , data )
    .then((response) => {
      // console.log(response.data, 'pin check');
      setMessage(response.data.message);
      setError(false);
    })
  }catch(error){
    // console.log(error , 'pin chedck erro')
    setError(error.response.data.errors);
    setMessage(false);
  }
}

  const deleteAddress = async() => {
    setLoader(true);
    setStatusShow(false);
      console.log(removeAddress , 'removeAddress');
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/delete` , {id :removeAddress})
      .then((response) => {
        setAddress(address.filter((address) => address.id !== removeAddress));
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'error delete address')
      setLoader(false);
    }
  }


  const updateAddress = async() => {
    setLoader(true);
    try{
      const data = {
        id            : editAddress.id,
        state_id      : editAddress.state,
        city_id       : editAddress.city,
        pin           : editAddress.pin,
        address       : editAddress.address,
        mobile        : editAddress.mobile,
        address_type  :  editAddress.address_type,
        land_mark     : editAddress.land_mark,
        street        : editAddress.street,
        alt_mobile    : newAddress.alt_mobile
      
      }
      console.log(data , 'check edit adress');
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/add/update` , data  )
      .then((response) => {
        console.log(response.data , 'add address')
        setLoader(false);
        setOpenNewAddress(false);
      })
    }catch(err) {
      // console.log(err , 'err');
      setLoader(false);
      setValidationError(err.response.data.errors)

    }
  };
 
  useEffect(() => {
    addressList();
    stateFetch();
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/>      
      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          <div className="account-section d-flex"> 
            <aside className="account-aside">
              <Sidebar /> 
            </aside>
            <main className="account-main-area">
            {
              loader ?  <div class="position-loader"><Loader /> </div> :
              <>
              <div className="account-main-inner position-relative">
                <div className="area-title">
                  <h4>My Address</h4>
                  {address && address.length < 3 ?
                  <>
                  {openNewAddress !==true &&
                    <button onClick={() => {setOpenNewAddress(true); setValidationError(false)}} className="myAddressNew"><BsPlusSquare /> Add New Address</button>
                  } 
                  </>
                  : ''
                }
                </div>     
                {openNewAddress && openNewAddress ?          
                <div className="AddressNewSection">
                  <div>
                      <div className="addressNewBlock d-flex">
                        <div className="address-line">
                          <label>State Name </label>
                          <select  className="form-control" onChange={state => handleState(state.target.value)}>
                              <option selected disabled>----- select from Below ----- </option>
                              {state && state ? state.map((item,index ) => {
                                return (
                                  <option key={index} value={item.id}> {item.name}</option>  
                                );
                              }) : <></>
                            }
                          </select>
                        {validationError && validationError.state_id ? <p style={{color : 'red' ,fontSize : 12}}>State Name is Missing </p> : ''}
                        </div>
                        <div className="address-line">
                          <label>City Name </label>
                          <select  className="form-control"  onChange={e=>setNewAddress({...newAddress , city:e.target.value}) }>
                              <option selected disabled>----- select from Below ----- </option>
                              {city && city ? city.map((item,index ) => {
                                return (
                                  <option key={index} value={item.id}> {item.name}</option>  
                                );
                              }) : <></>
                            }
                          </select>
                        {validationError && validationError.city_id ? <p style={{color : 'red' ,fontSize : 12}}>City Name is Missing </p> : ''}
                        </div>
                      
                        <div className="address-line">
                          <label>Pin Code </label>
                          <input type="text" name="name" maxLength={8}  className="form-control"  placeholder=" Enter Pin Code"
                            onKeyUp={() => pinCheck(newAddress.pin)}
                            onChange={e=>setNewAddress({...newAddress , pin:e.target.value})}
                          />
                          {message && message  ? <p style={{fontSize : '12px' , color : 'green'}}>{message}</p> : ''}
                          {error && error  ? <p style={{fontSize : '12px' , color : 'red'}}>{error.pin}</p> : ''}
                        {validationError && validationError.pin ? <p style={{color : 'red' ,fontSize : 12}}>Pincode is Missing </p> : ''}
                        </div>
                        <div className="address-line">
                          <label>Mobile Phone</label>
                          <input type="number" name="phone" className="form-control" placeholder=" Enter Mobile Number" 
                            onChange={e=> setNewAddress({...newAddress , mobile:e.target.value})}
                            defaultValue={address && address.mobile}
                          />
                        {validationError && validationError.mobile ? <p style={{color : 'red' ,fontSize : 12}}>Mobile Number is Missing </p> : ''}
                        </div>
                        <div className="address-line">
                          <label>Alternate Phone</label>
                          <input type="number" name="phone" className="form-control" placeholder=" Enter Alternate  Number" 
                            onChange={e=> setNewAddress({...newAddress , alt_mobile:e.target.value})}
                          />
                        </div>
                        <div className="address-line">
                          <label>Landmark</label>
                          <input type="text" name="phone" className="form-control" placeholder=" Enter your Landmark " 
                            onChange={e=> setNewAddress({...newAddress , land_mark:e.target.value})}
                          />
                        </div>
                        <div className="address-line">
                          <label>Street Name</label>
                          <input type="text" name="phone" className="form-control" placeholder=" Enter street Name" 
                            onChange={e=> setNewAddress({...newAddress , street:e.target.value})}
                          />
                        </div>
                        <div className="address-line">
                          <label>Address Type</label>
                          <select  className="form-control" defaultValue={newAddress && newAddress.address_type} onChange={e=>setNewAddress({...newAddress , address_type:e.target.value}) }>
                              <option selected disabled>------ select from below ------ </option>
                              <option value="H">Home </option>
                              <option value="O">Others</option>
                          </select>
                        {validationError && validationError.address_type ? <p style={{color : 'red' ,fontSize : 12}}>{validationError.address_type} </p> : ''}
                        </div>
                        <div className="address-line textarea">
                          <label>Address </label>
                          <textarea name="address" className="form-control" placeholder="Enter House no, street, area"
                                onChange={e=> setNewAddress({...newAddress , address:e.target.value})}
                          ></textarea>
                        {validationError && validationError.address ? <p style={{color : 'red' ,fontSize : 12}}>{validationError.address} </p> : ''}

                        </div>
                        {/* <div className="address-line"><label>City </label><input type="text" name="city" className="form-control" placeholder="Enter City" /></div>
                        <div className="address-line"><label>State </label><input type="text" name="state" className="form-control" placeholder="Enter State" /></div> */}
                      </div>
                      <div className="addAddressBtn">
                        <button onClick={() => setOpenNewAddress(false)} type="button">Cancel</button>
                        <button type="button" onClick={() => addAddress()}>Submit</button>
                      </div>
                  </div>
                </div> 
                :
                <>
                {/* edit address from */}
                {
                  openEditAddress && openEditAddress == true ?
                  <div className="AddressNewSection">
                        <div className="addressNewBlock d-flex">
                        <div className="address-line">
                            <label>State Name </label>
                            <select 
                            // defaultValue={editAddress && editAddress.state} 
                             className="form-control" onChange={state => handleState(state.target.value)}>
                                <option selected disabled>----- select from Below ----- </option>
                                {state && state ? state.map((item,index ) => {
                                return (
                                    <option key={index} value={item.id}> {item.name}</option>  
                                );
                                }) : <></>
                            }
                            </select>
                        {validationError && validationError.state_id ? <p style={{color : 'red' ,fontSize : 12}}>State Name is Missing </p> : ''}

                        </div>
                        <div className="address-line">
                            <label>City Name </label>
                            <select  
                            // defaultValue={editAddress && editAddress.city} 
                            className="form-control"  onChange={e=>setEditAddress({...editAddress , city:e.target.value}) }>
                                <option selected disabled>----- select from Below ----- </option>
                                {city && city ? city.map((item,index ) => {
                                return (
                                    <option key={index} value={item.id}> {item.name}</option>  
                                );
                                }) : <></>
                            }
                            </select>
                        {validationError && validationError.city_id ? <p style={{color : 'red' ,fontSize : 12}}>City Name is Missing </p> : ''}
                        </div>
                        
                        <div className="address-line">
                              {
                                pinLoader && pinLoader  ?  <Loader /> : 
                                <>
                                  <label>Pin Code </label>
                                      <input type="text" name="name" maxLength={8}  className="form-control"  placeholder=" Enter Pin Code"
                                      onKeyUp={() => pinCheckEdit(editAddress.pin)}
                                      onChange={e=>setEditAddress({...editAddress , pin:e.target.value})}
                                      defaultValue={editAddress && editAddress.pin}
                                      />
                                  {message && message  ? <p style={{fontSize : '12px' , color : 'green'}}>{message}</p> : ''}
                                  {error && error  ? <p style={{fontSize : '12px' , color : 'red'}}>{error.pin}</p> : ''}
                        {validationError && validationError.pin ? <p style={{color : 'red' ,fontSize : 12}}>{validationError.pin}</p> : ''}

                                </>
                              }
                        </div>
                        <div className="address-line">
                            <label>Mobile Phone</label>
                            <input type="number" name="phone" className="form-control" placeholder=" Enter Mobile Number" 
                            onChange={e=> setEditAddress({...editAddress , mobile:e.target.value})}
                            defaultValue={editAddress && editAddress.mobile}
                            />
                        </div>
                        <div className="address-line">
                            <label>Alternate Phone</label>
                            <input type="number" name="phone" className="form-control" placeholder=" Enter Alternate Number" 
                            onChange={e=> setEditAddress({...editAddress , alt_mobile:e.target.value})}
                            defaultValue={editAddress && editAddress.alt_mobile}
                            />
                        </div>
                        <div className="address-line">
                            <label>Landmark</label>
                            <input type="text" name="phone" className="form-control" placeholder=" Enter your Landmark" 
                            onChange={e=> setEditAddress({...editAddress , land_mark:e.target.value})}
                            defaultValue={editAddress && editAddress.land_mark}
                            />
                        </div>
                        <div className="address-line">
                            <label>Street Name</label>
                            <input type="text" name="phone" className="form-control" placeholder=" Enter Street Name" 
                            onChange={e=> setEditAddress({...editAddress , street:e.target.value})}
                            defaultValue={editAddress && editAddress.street}
                            />
                        </div>
                        <div className="address-line">
                            <label>Address Type</label>
                            <select  className="form-control" defaultValue={editAddress && editAddress.address_type} onChange={e=>setEditAddress({...editAddress , address_type:e.target.value}) }>
                                <option selected disabled>------ select from below ------ </option>
                                <option value="H">Home </option>
                                <option value="O">Others</option>
                            </select>
                        </div>
                        <div className="address-line textarea">
                            <label>Address </label>
                            <textarea name="address" className="form-control" placeholder="Enter House no, street, area"
                                onChange={e=> setEditAddress({...editAddress , address:e.target.value})}
                                defaultValue={editAddress && editAddress.address}
                            ></textarea>
                        </div>
                        </div>
                        <div className="addAddressBtn">
                        <button onClick={() => setEditNewAddress(false)} type="button">Cancel</button>
                        <button type="button" onClick={() => updateAddress()}>Submit</button>
                        </div>
                    </div>
                   : 

                <div className="myAddressList">
                  {address && address.length?
                  <>
                  {address && address.map((item,index) => {
                      return (
                        <div key={index} className="addressListItems">
                          <div className="addressTitle"> {item.name} </div>
                          <div className="addressDetail"> <>
                            { item && item.street     !== null ? (item.street + ' , ') : '' }
                            {  item.address && item.address   !== null ? ( '  ' + item.address + ' , ') : ''  }
                            { item && item.land_mark  !== null ? (item.land_mark + ',') : ' '  }
                            { item && item.city_name  !== null ? (item.city_name   + ' , '  ) : '' }
                            {  item.state_name !== null ? (item.state_name ) : '' }
                            </> <span > <Badge bg="info" className="text-white" > {item.address_type === "O" ? 'Office' :  "Home" } </Badge></span></div>

                          <div className="addressMobile">
                            <span>Mobile: </span><strong>{item.mobile}</strong> 
                              <span className="ms-2">Alternate Mobile: </span><strong>{item.alt_mobile}</strong> 
                              </div>
                          <div className="addressButtons d-flex">
                            <button type="button" onClick={() => handleDelete(item.id)} >Remove</button>
                            <button type="button" onClick={() =>handleEdit(item) }>Edit</button>
                          </div>
                        </div>
                      );
                    })
                  }
                  </>
                  : 
                  <div style={{textAlign: 'center',marginTop:20,paddingBottom:20}}>
                    <img src={require('../../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                    <p>No Address Found</p>
                  </div>
                  }
                </div>
                }
                {/* edit address from */}
                </>
                }      
              </div>
              </>
            }
            </main>
          </div>
        </div>
        <Modal size="md" show={statusShow}  centered>
          <Modal.Header className="py-1">
              <Modal.Title className="h6">Do you want to  Remove address ? </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="p-1  justify-content-start" style={{display : 'flex'}}>
              <button  onClick={() => setStatusShow(false)}  className="btn btn-light btn-sm">Close</button>
              <button type="button"  className="btn btn-primary btn-sm w-auto text-dark" onClick={()=> deleteAddress()} >Remove</button>
          </Modal.Footer>
        </Modal> 

      </section>
      <Outlet />
    </div>
  );
}

export default Address;
