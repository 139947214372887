import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import Loader from "../components/Slider/loader";

const CancellationPolicy = () => {

    const [loader , setLoader ] = useState();
    const [isProgress, setIsProgress] = useState(false);
    const [text, setText] = useState('');
    console.log(text,'texttext');

    const cancellationPolicy = async () => {
        setLoader(true)
        try {
          const response = await axios.get(`user/setting/cancelPolicy/view`);
          if (response.data.success) {
            setText(response.data.data.desc);
          } else {
            alert('error cancellation Policy api call')
          }
          console.log(response);
          setLoader(false);
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      }
    
     useEffect(() => {
        cancellationPolicy();
    },[])

    return (

        <div style={styles.bgBody}>
          {loader && loader ? <div className="position-loader"><Loader  /></div>  : 
          <div style={styles.bgBodyInner}>
            <div style={{backgroundColor: '#fff', height: '100%',}}>
            <p style={styles.pageNameText}>Cancellation and Refund Policy</p>
            
            <div style={styles.bgCard}>
                <div  style={styles.aboutTextSection}>
                  <p>{ReactHtmlParser(text)}</p>
                  {/* <p>Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below</p> */}
                </div>
            </div>
            </div>

          </div>
          }
          {/* Divider */}
          <div style={styles.divider}></div>
         </div>
    );
};

export default CancellationPolicy;
const styles = {
  divider: { padding: 40 },
  bgBody:{backgroundColor:'#f1f3f6',height: '100%'},
  bgBodyInner: { paddingLeft:80,paddingRight:80,textAlign: 'justify',paddingTop:20},
  pageNameText:{fontSize:18, color: '#000000', fontWeight: 'bold',
  marginTop: 10,textAlign:'center',paddingTop:20 }, 
  bgCard: {
      marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5,borderColor: '#ddd',padding:10
  },
  aboutTextSection: { marginHorizontal: 10, padding:10},
}