import React, {useContext, useEffect, useState} from "react";
import { Outlet, Link, useLocation , useNavigate } from "react-router-dom";
import { BiUserPin, BiArchive, BiBookmarkHeart, BiPowerOff, BiChevronRight, BiMessageError } from "react-icons/bi";
import { BsCursor } from "react-icons/bs";
import { FiEdit2, FiShoppingCart } from "react-icons/fi";
import Cookies from "js-cookie";
import AuthUser from "../../context/AuthUser";
import UserNameContext from "../../context/profile";
import axios from "axios";
import { decrpt } from "../../../components/Slider/encryption";
import loader from "../../../components/Slider/loader";




function Sidebar(props) {
  const [loader , setLoader] = useState();

  const location = useLocation();
  const Auth = useContext(AuthUser);
  const navigate = useNavigate();
  const [auth , setAuth] = useState();

  const userNameFinal = useContext(UserNameContext);

  const fetchUserDetails = async() => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      // console.log(token , 'token check');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/profile`)
      .then((response) => {
        userNameFinal.setUserName(response.data.data.name);
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'errors use details');
      setLoader(false);
    }
  }



  // console.log(userNameFinal, 'userNameFinal on sidebar');
  const logOutHandler = () => {     
    Auth.setAuth(false);
    Cookies.remove('s_l');
    navigate('/login');
  }
  //  let user_Data = location && location.state.userData; 
  let name = props.username && props.username.name;
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserDetails();
  }, [location]);
  return (
    <div className="account-sidebar">
      <div className="account-profile-block">
        <img src={require('../../../assets/man.png')} alt="profile" />
        <h4 className="account-person">
          <small>Hello</small>
          { userNameFinal && userNameFinal.UserName }
        </h4>
        {/* <button className="edit-profile-btn"><FiEdit2 /></button> */}
      </div>
      <ul className="account-pages-block">
        <li><Link to="/your-account/profile"><BiUserPin className="accountIcon" /> My Profile <BiChevronRight className="icon-navi"/></Link></li>
        <li><Link to="/your-account/orders"><BiArchive className="accountIcon" /> My Orders <BiChevronRight className="icon-navi"/></Link></li>
        <li><Link to="/your-account/address"><BsCursor className="accountIcon" /> My Address <BiChevronRight className="icon-navi"/></Link></li>
        <li><Link to="/your-account/wishlist"><BiBookmarkHeart className="accountIcon" /> My Wislist <BiChevronRight className="icon-navi"/></Link></li>
        <li><Link to="/cart"><FiShoppingCart className="accountIcon" /> My Cart <BiChevronRight className="icon-navi"/></Link></li>
        <li><Link to="/your-account/bankDetails"><BiMessageError className="accountIcon" /> Bank Information <BiChevronRight className="icon-navi"/></Link></li>
        <li><a  onClick={()=>logOutHandler()}><BiPowerOff className="accountIcon"  /> Logout <BiChevronRight className="icon-navi"/></a></li>
      </ul>
    </div>
  );
}

export default Sidebar;
