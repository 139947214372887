import MainNavigation from './navigation/MainNavigation';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
//  axios.defaults.baseURL = 'https://jvmart.backend.webinfoghy.co.in/public/api';
//  axios.defaults.baseURL = 'https://super.jivimart.com/public/api';
// axios.defaults.baseURL = 'https://avmart.backend.3decolutions.com/public/api';
axios.defaults.baseURL ="https://backend.aivimart.com/api/";

function App() {
  
  return (
      <MainNavigation />
      // <PaytmButton />
  );
}
 
export default App;
