import React, {useEffect, useState} from "react";
import { Outlet, Link, useLocation,useNavigate  } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";
import { decrpt } from "../../../components/Slider/encryption";
import Loader from "../../../components/Slider/loader";
import Badge from 'react-bootstrap/Badge';
import { Modal } from "react-bootstrap";


function OrdersDetail() {
  const navigate = useNavigate()
  const location = useLocation();
  const [orderStatus, setOrderStatus] = useState('1');
  const [loader , setLoader] = useState();
  const [orderData , setOrderData] = useState();
  const [orderDetails , setOrderDetails] = useState();
  const [address , setAddress] = useState();
  const [cancelShow,setCancelShow] = useState(false);
  const [returnShow,setReturnShow] = useState(false);
  const [cancelProduct , setCancelProduct ] = useState();
  const [bankDetailsShow,setBankDetailsShow] = useState(false);
  const [bankErrors , setBankErrors] = useState('');
  const [bankDetailsData,setBankDetailsData] = useState();
  console.log(bankErrors && bankErrors,'bankErrors bankDetailsData');

  let order_details = location.state && location.state.item; 

  console.log(order_details,'order_details check');
  // console.log(orderData,'orderData');

  const [cancelPolicyList,SetCancelPolicyList]=useState();

  const [cancelReasonData,setCancelReasonData]=useState({
    order_details_id:'',
    cancel_policy_id:'',
    remarks:''
  })

  const [returnPolicyList,SetReturnPolicyList]=useState();

  const [returnReasonData,setReturnReasonData]=useState({
    order_id:order_details && order_details,
    product_id:'',
    return_policy_id:'',
    comment:'',
    acc_holder_name:bankDetailsData && bankDetailsData.name ? bankDetailsData.name : '',
    bank_name:bankDetailsData && bankDetailsData.bnk_name ? bankDetailsData.bnk_name : '',
    branch:bankDetailsData && bankDetailsData.branch_name ? bankDetailsData.branch_name : '',
    acc_no:bankDetailsData && bankDetailsData.ac_no ? bankDetailsData.ac_no : '',
    ifsc:bankDetailsData && bankDetailsData.ifsc ? bankDetailsData.ifsc : '',
    micr:bankDetailsData && bankDetailsData.micr_code ? bankDetailsData.micr_code : '',
  })

  console.log(returnReasonData && returnReasonData,'returnReasonData returnReasonData');

  const orderDetailsfetch = async() => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      console.log(token,'token token');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/order/details` , {order_id :order_details })
      .then((response) => {
        console.log(response.data , 'user details');
        setOrderDetails(response.data.order_details);
        setOrderData(response.data.order_data);
        setAddress(response.data.shipping_address);
        setLoader(false);
      })
    }catch(error) {
      console.log(error, 'error error')
      setLoader(false);
    }
  }


  const cancelModalClose = () => {
    setCancelShow(false);
  };




const fetchCancelPolicyList = async()=>{
    setLoader(true);
    try {
      const response = await axios.get(`/cancel/policy/dropdown`);
        if (response.data.success) {
          SetCancelPolicyList(response.data.data);
        }
    } catch (error) {
        console.log(error);
    }
}

const cancelModalOpen = (item) => {

setCancelReasonData({
    ...cancelReasonData,
    order_details_id : item,  
})
setCancelShow(true);
};

const handleCancelReasonSelect = (item) => {
  setCancelReasonData({
    ...cancelReasonData,
    cancel_policy_id : item, 
})

}

const cancelModalConfirm = ()=>{
  setCancelProduct (true);
  setCancelShow(false);
}

  const cancelOrder = async () => {
    setLoader(true);
    try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`user/order/cancel/product_wise`, cancelReasonData);
        console.log('delete response', response);
        if (response.data.success == true) {
          orderDetailsfetch();
          cancelModalClose();
          setLoader(false);
          setCancelProduct (false);
          } else {
            cancelModalClose();
            setLoader(false);
            setCancelProduct (false);
        }
    } catch (error) {
        console.log("Something Went Wrong Please Try Again", error);
        setLoader(false);
        setCancelProduct (false);
    }
}



const returnModalClose = () => {
  setReturnShow(false);
};

const returnModalOpen = (item) => {
  setReturnReasonData({
    ...returnReasonData,
    // order_id: item.id,
    product_id: item.product_id, 
})
  setReturnShow(true);
};

const handleReturnReasonSelect = (item) => {
  setReturnReasonData({
    ...returnReasonData,
    return_policy_id : item, 
})
}

const fetchReturnPolicyList = async()=> {
  setLoader(true);
  try {
    const response = await axios.get(`/return/policy/dropdown`);
      if (response.data.success) {
        SetReturnPolicyList(response.data.data);
      }
  } catch (error) {
      console.log(error);
  }
}



const bankDetailsOpen =()=>{
  setReturnShow(false);
  setBankDetailsShow(true)
  // navigate('../orders/bankDetails' );
}

const bankDetailsClose =()=>{
  setReturnShow(false);
  setBankDetailsShow(false)
  // navigate('../orders/bankDetails' );
}

const returnOrder = async () => {
  setLoader(true);
  try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/order/return`, returnReasonData);
      console.log('delete response', response);
      if (response.data.success == true) {
        orderDetailsfetch();
        returnModalClose();
        setLoader(false);
        setBankDetailsShow(false);
        } else {
          returnModalClose();
          setLoader(false);
          setBankDetailsShow(false)
      }
  } catch (error) {
      console.log("Something Went Wrong Please Try Again", error.response.data);
      setLoader(false);
      setBankErrors(error.response.data.errors)
      setBankDetailsShow(true)
      // console.log("Someth", error.response.data);

  }
}

const bankDetailsFetch = async()=>{
  setLoader(true);
  try {      
    let token = decrpt(Cookies.get('s_l'));
    console.log(token,'token token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response = await axios.get(`/user/bank/details`)
    if (response.data) {
      console.log(response.data , 'user bank details');
      setBankDetailsData(response.data.order_data);
      setLoader(false);
    }
}catch(error) {
    console.log(error, 'error error')
    setLoader(false);
}

}
  useEffect(() => {
    setReturnReasonData({
      ...returnReasonData ,
      acc_holder_name:bankDetailsData && bankDetailsData.name ? bankDetailsData && bankDetailsData.name : '',
      bank_name:bankDetailsData && bankDetailsData.bnk_name ? bankDetailsData && bankDetailsData.bnk_name : '',
      branch:bankDetailsData && bankDetailsData.branch_name ? bankDetailsData && bankDetailsData.branch_name : '',
      acc_no:bankDetailsData && bankDetailsData.ac_no ? bankDetailsData && bankDetailsData.ac_no : '',
      ifsc:bankDetailsData && bankDetailsData.ifsc ? bankDetailsData && bankDetailsData.ifsc : '',
      micr:bankDetailsData && bankDetailsData.micr_code ? bankDetailsData && bankDetailsData.micr_code : '',
    })
  },[bankDetailsData])


  useEffect(() => {
    window.scrollTo(0, 0);
    orderDetailsfetch();
    fetchCancelPolicyList(); 
    fetchReturnPolicyList();
    bankDetailsFetch();
    console.log(bankErrors && bankErrors , 'bankErrors')
  }, [location]);


  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      {
        loader && loader ?  <div className="position-loader"><Loader  /></div> :
        <>
          {/* Produt List */}
          <section className="light-grey">
            <div className="container">
              <div className="account-section d-flex" style={{alignItems: 'baseline'}}> 
                <main className="account-main-area" style={{margin:'auto'}}>
                  <div className="account-main-inner">
                    <div className="order-title">
                      <h5><strong>Order ID :</strong> {orderData && orderData.order_uuid}</h5>
                      <h5><strong>Order Date :</strong> {orderData && orderData.order_date}</h5>
                      {/* <h5><strong>Order Status :</strong> 
                      <span style={{paddingLeft:10}}>
                        {orderData && orderData.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                        (orderData && orderData.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                        (orderData && orderData.order_status == 6)  ? <Badge bg="danger"> Cancelled By Seller </Badge> : 
                        (orderData && orderData.order_status == 7)  ? <Badge bg="danger"> Cancelled </Badge> : 
                        (orderData && orderData.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                        (orderData && orderData.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                        <Badge bg="success"> Delivered</Badge> }
                      </span>
                      </h5> */}
                        {/* check cancell by admin on status 8 and 5 is for deliverd */}
                    </div>
                    <div className="order-detail-delivery">
                      {orderData && orderData.order_status == 5 && 
                      <h5><strong>Delivered On :</strong> {orderData.delivered_date}</h5>
                      }
                      <h5><strong>Delivery Address : </strong></h5>
                      <h5> <>  
                      { address && address.street     !== null ? (address.street + ' , ') : '' }
                            {  address && address.address   !== null ? ( '  ' + address.address + ' , ') : ''  }
                            { address && address.land_mark  !== null ? (address.land_mark + ',') : ' '  }
                            { address && address.city  !== null ? (address.city   + ' , '  ) : '' }
                            { address && address.state !== null ? (address.state ) : '' }
                            </> <span > <Badge bg="info" className="text-white" >
                            {address  && address.address_type === "O" ? 'Office' :  "Home" } 
                        </Badge></span></h5>
                      <h5><strong>Payment Mode :</strong> {orderData && orderData.payment_type == "COD" ? 
                       <Badge bg="success">  Cash On delivery </Badge> : <Badge bg="primary"> Online </Badge>}</h5>
                    </div>
                  {
                    orderDetails && orderDetails.map((item,index) => {
                      return (
                        <div key={index} className="area-order">
                          <div className="order-image">
                              {/* <img src="../../../images/product/1.png" /> */}
                              <img src={item.product_image} />
                              {/* <div className="more-item">+ {item.product_image.length} items</div> */}
                            </div>
                            <div className="order-content-detail">
                              <div><h5 className="cart-item-title">{item.product_name}</h5></div>
                              <div> <strong> Seller Name </strong> :  {item.seller_name} </div>
                              <div className="d-block" >
                                <div className="d-flex align-items-center w-100" style={{columnGap: 20}}>
                                  <div className="orderId"><strong>Quantity :</strong> {item.quantity}</div>
                                  {
                                    item.product_size != null ?
                                    <div className="orderId"><strong>Size :</strong> {item.product_size}</div>
                                    : ''
                                  }
                                  <div className="orderId"><strong>Order Status :</strong> 
                                      <span style={{paddingLeft:10}}>
                                        {item && item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                        (item && item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                        (item && item.order_status == 6)  ? <Badge bg="danger"> Cancelled By Seller </Badge> : 
                                        (item && item.order_status == 7)  ? <Badge bg="danger"> Cancelled </Badge> : 
                                        (item && item.order_status == 5)  ? <Badge bg="success"> Delivered </Badge> : 
                                        (item && item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                        (item && item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                        <Badge bg="success"> Cancelled By Admin</Badge> }
                                      </span>
                                    </div> 
                                </div>
                                  <div className="d-flex align-items-center">
                                      <div className="order-price">Order Amount : <strong className="me-2">₹ {item.price}</strong> <del> ₹{item.mrp}</del> </div>
                                      {
                                        (item && item.order_status == 1) || (item && item.order_status == 2) || (item && item.order_status == 3) || (item && item.order_status == 4) ?

                                        <button className="cancel-btn-product ml-auto" type="button" onClick={()=> cancelModalOpen(item.id)}>Cancel</button>:

                                        (item && item.order_status == 6) || (item && item.order_status == 7) ?
                                         <div className="ml-auto">
                                            <h4 className="returned">Cancelled</h4>
                                        </div>
                                       :
                                       <>
                                       {(item && item.is_returned == 2) ?
                                       <div className="ml-auto">
                                         <h4 className="returned">Returned</h4>
                                       </div> 
                                       :
                                       <div className="return-btn ml-auto">
                                         <button onClick={()=>returnModalOpen(item)}>Return</button>
                                       </div>
                                       }
                                     </>
                                      }
                                  </div>

                              </div>
                            </div>
                            {/* {(orderData && orderData.order_status == 5) &&
                            <>
                            {(item && item.is_returned == 2) ?
                            <div>
                              <h4 className="returned">Returned</h4>
                            </div> 
                            :
                            <div className="return-btn">
                              <button onClick={()=>returnModalOpen(item.product_id)}>Return</button>
                            </div>
                            }
                          </>
                          } */}
                       </div>
                      );
                    })
                  }
                  </div>
                </main>
                
                <div className="sectionRight">
                  <div className="section-title">Order Summary</div>
                  <div className="section-item-summery">
                    <div className="section-summery-value">
                      <div>Order Value</div>
                      <div>₹ {orderData && orderData.total_price}</div>
                    </div>
                    <div className="section-summery-value">
                      <div>Delivery Charge</div>
                      <div>₹ {orderData && orderData.shipping_charge}</div>
                    </div>
                    <div className="section-summery-value">
                      <div>Order Total</div>
                      <div>₹ {(orderData && parseFloat(orderData.total_price)) + (orderData && parseFloat(orderData.shipping_charge))}</div>
                    </div>
                  </div>
    

                    {/* {(orderData && orderData.order_status == 6) || (orderData && orderData.order_status == 7) || (orderData && orderData.order_status == 5) || (orderData && orderData.order_status == 3) || (orderData && orderData.order_status == 4)?
                  '':
                  <div className="section-cancel">
                    <h4>Do you want to cancel this order ?</h4>
                    <button onClick={()=>cancelModalOpen()}>Cancel</button>
                  </div>
                  } */}
                </div>
              </div>
            </div>
          </section>
          {/* Cancel modal */}
          <Modal size="md" show={cancelShow} onHide={cancelModalClose} centered>
            <Modal.Body>
              <div>
                <p className="mb-1">Select Cancel policy *</p>
                <div className="slidepanel-inner">
                    {cancelPolicyList && cancelPolicyList.map((item,index)=>{
                      return(
                        <label className="slidepanel-label" key={index}>
                          <input type="radio" className="label-checkbox" value={item.id}
                          name="check" onChange={(e) => handleCancelReasonSelect(e.currentTarget.value)}/> {item.policy}
                        </label>
                      )
                    })} 
                  </div>
              </div>
              <div className="mt-2">
                <p className="mb-1">Remarks</p>
                <input type="text"  class="form-control" 
                onChange={e => setCancelReasonData({...cancelReasonData , remarks:e.target.value})}/>
              </div>
          </Modal.Body>
          <Modal.Footer className="p-1 justify-content-end" >
              <button onClick={()=>cancelModalClose()} className="btn btn-light btn-md">
                  Close
              </button>
              <button className="btn btn-md btn-success" onClick={()=>cancelModalConfirm()}>
                  Submit
              </button>
            </Modal.Footer>
          </Modal>

          {/* return modal */}
          <Modal size="md" show={returnShow} onHide={returnModalClose} centered>
            <Modal.Body>
              <div>
                <p className="mb-1">Select Return policy *</p>
                <div className="slidepanel-inner">
                    {returnPolicyList && returnPolicyList.map((item,index)=>{
                      return(
                        <label className="slidepanel-label" key={index}>
                          <input type="radio" className="label-checkbox" value={item.id}
                          name="check" onChange={(e) => handleReturnReasonSelect(e.currentTarget.value)} required/> {item.return_policy}
                        </label>
                      )
                    })} 
                  </div>
              </div>
              <div className="mt-2">
                <p className="mb-1">Comment</p>
                <input type="text"  class="form-control" 
                onChange={e => setReturnReasonData({...returnReasonData , comment:e.target.value})} required/>
              </div>
          </Modal.Body>

          <Modal.Footer className="p-1 justify-content-end" >
              <button onClick={()=>returnModalClose()} className="btn btn-light btn-md">
                  Close
              </button>
              <button  className="btn btn-md btn-success" onClick={()=>bankDetailsOpen()}>
                  Submit
              </button>
          </Modal.Footer>

          </Modal>  

          {/* bankdetails modal */}
          <Modal size="lg" show={bankDetailsShow} onHide={bankDetailsClose} centered>
            <Modal.Body>
              {(bankDetailsData && bankDetailsData.name ||  bankDetailsData && bankDetailsData.bnk_name || bankDetailsData && bankDetailsData.branch_name || bankDetailsData && bankDetailsData.ac_no || bankDetailsData && bankDetailsData.ifsc || bankDetailsData && bankDetailsData.micr_code)  !== null  ?
              <>
              <div className="bankDetailsData">
                <p className="bankDetailsHeader">Account Holder Name : <span>{bankDetailsData && bankDetailsData.name}</span></p>
                <p className="bankDetailsHeader">Bank Name : <span>{bankDetailsData && bankDetailsData.bnk_name} </span></p>
                <p className="bankDetailsHeader">Branch Name : <span>{bankDetailsData && bankDetailsData.branch_name} </span></p>
                <p className="bankDetailsHeader">Account Number : <span>{bankDetailsData && bankDetailsData.ac_no}</span></p>
                <p className="bankDetailsHeader">IFSC Code : <span>{bankDetailsData && bankDetailsData.ifsc}</span></p>
                {bankDetailsData && bankDetailsData.micr_code !== null?
                  <p className="bankDetailsHeader">MICR Code : <span>{bankDetailsData && bankDetailsData.micr_code} </span></p>:
                  <p></p>
                }
              </div>
              </> :
              <>
                <div class="account-main-inner position-relative ">
                    <div class="area-title"><h4>Fill Bank Details</h4></div>
                </div>
                <div>
                <div className=" bankDetails-line mb-3 mt-3">
                    <label>Account Holder Name</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter Account Holder Name" onChange={e => setReturnReasonData({...returnReasonData , acc_holder_name:e.target.value})}/>
                    {
                      bankErrors?.acc_holder_name ? <p style={{color : 'red' , fontSize : 12}}>{bankErrors.acc_holder_name}</p> : ''
                    }
                </div>
                <div className="bankDetails-line mb-3">
                    <label>Bank Name</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter Bank Name" onChange={e => setReturnReasonData({...returnReasonData , bank_name:e.target.value})}/>
                    {
                      bankErrors?.bank_name ? <p style={{color : 'red' , fontSize : 12}}>{bankErrors.bank_name}</p> : ''
                    }
                </div>
                <div className="bankDetails-line mb-3">
                    <label>Branch Name</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter Branch Name" onChange={e => setReturnReasonData({...returnReasonData , branch:e.target.value})}/>
                    {
                      bankErrors?.branch ? <p style={{color : 'red' , fontSize : 12}}>{bankErrors.branch}</p> : ''
                    }
                </div>
                <div className="bankDetails-line mb-3">
                    <label>Account Number</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter Account Number" onChange={e => setReturnReasonData({...returnReasonData , acc_no:e.target.value})}/>
                    {
                      bankErrors?.acc_no ? <p style={{color : 'red' , fontSize : 12}}>{bankErrors.acc_no}</p> : ''
                    }
                </div>
                <div className="bankDetails-line mb-3">
                    <label>IFSC Code</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter IFSC Code" onChange={e => setReturnReasonData({...returnReasonData , ifsc:e.target.value})}/>
                    {
                      bankErrors?.ifsc ? <p style={{color : 'red' , fontSize : 12}}>{bankErrors.ifsc}</p> : ''
                    }
                </div>
                <div className="bankDetails-line mb-3">
                    <label>MICR Code (Optional)</label>
                    <input type="text" name="phone" className="form-control" placeholder=" Enter MICR Code" onChange={e => setReturnReasonData({...returnReasonData , micr:e.target.value})}/>
                </div>
                </div>
              </>
            }
          </Modal.Body>

          <Modal.Footer className="p-1 justify-content-end" >
              <button onClick={()=>bankDetailsClose()} className="btn btn-light btn-md">
                  Close
              </button>
              <button className="btn btn-md btn-success" onClick={()=>returnOrder()}>
                  Submit
              </button>
          </Modal.Footer>

          </Modal>  

          {/* cancel product */}
          <Modal size="md" show={cancelProduct} centered>
            <Modal.Body>
                <p>Are You Sure to cancel the Product ? </p>
            </Modal.Body>
            <Modal.Footer className="p-1 justify-content-end" >
              <button onClick={()=>setCancelProduct(false)} className="btn btn-light btn-md">
                  No, dont Cancel
              </button>
              <button className="btn btn-md btn-success" onClick={()=>cancelOrder()}>
                  Cancel Order
              </button>
          </Modal.Footer>
          </Modal>
          <Outlet />
        </>
      }
    </div>
  );
}

export default OrdersDetail;
