import React, { useEffect } from "react";
import { Outlet} from "react-router-dom";


function Products() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Outlet />
    </div>

  );
}

export default Products;
