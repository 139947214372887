
import * as React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../../App.css'
import Abc from "../../assets/Aivimartslider.png"

function HomeSlider(props, slideImages) {
  // console.log(props.props.state , 'props. data');
  let sliderData = props.props.state;
  console.log("sliderData2323",sliderData)
  return (
    <div className="homeSlider">
      <Slide easing="ease">
    { sliderData && sliderData.sliders.length > 0 ? sliderData.sliders.map((item,index) => {
      return (
        <>
        <div key={index} className="each-slide">
            {/* <div  style={{'backgroundImage': `url(${item.image})`}}>
            </div> */}
            <div  style={{'backgroundImage': `url(${Abc})`}}>
                {/* <span>{item && item.heading}</span> */}
            </div>
        </div>
        </>
      );
    })
  : 
  <>
   <div className="each-slide">
            <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
              {/* <span>No Image Selected on backend</span> */}
            </div>
        </div>
  </>
  }
      </Slide>
    </div>
  );
}



export default HomeSlider;
