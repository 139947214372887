
import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../App.css';
import { AiOutlineShopping,AiOutlineHeart } from "react-icons/ai";


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  }
};

function BestDeal(props) {
  const [deviceSize, setDeviceSize] = useState()
  const { innerWidth: width} = window;
  // let latestProducts = props.props.state.latest_products ;
  console.log(props.best_deal , 'props check lateest products');


  const checkDevice=()=>{
    if (width <= 425) {
      // alert('mobile');
      setDeviceSize("mobile");
    } else if (width <= 760) {
      // alert('tablet');
      setDeviceSize("tablet");
    } else {
      // alert('desktop');
      setDeviceSize("desktop");
    }
  }

  useEffect(()=>{
    checkDevice();
  })
  
  return (
    <div className="">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="500ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
        deviceType={deviceSize}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {props.best_deal && props.best_deal ? props.best_deal.map((item,index) => {
            return (
              <div className="section__product">
              <Link
              //  to="/product-detail"
              //  to="product/product-detail"
              to={`/product/product-detail/${item.id}/${(item.name).replace(/\s+/g, '-')}` }
               state={item}
               > 
              <div className="product__image" href="#">
                <img src={item && item.main_image} alt="" />
              </div>
              </Link>
              {/* <div className="product__actions">
                  <div className="product__item"><button><AiOutlineShopping /></button></div>
                  <div className="product__item"><button><AiOutlineHeart /></button></div>
                </div> */}
              <Link
              //  to="/product-detail"
              //  to="product/product-detail"
              to={`/product/product-detail/${item.id}-${(item.name).replace(/\s+/g, '-')}` }
               state={item} style={{textDecoration: 'none'}}
               > 
              <div className="product__content">
                <div className="product__title" href="#">{item.name}</div>
                <div className="product__meta">
                  <span className="product__price sale">₹{item && item.price}</span><span className="product__del">₹{item && item.mrp}</span>
                </div>
              </div>
              </Link>
              </div>
            );
          } ): <></>
        }
      </Carousel>
      
    </div>
  );
}



export default BestDeal;
