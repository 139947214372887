

import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate  } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import Loader from "../components/Slider/loader";

const Shipping = () => {

    const [loader , setLoader ] = useState();
    const [text, setText] = useState('');
    console.log(text,'text text');

    // const disclaimer = async () => {
    //     setLoader(true)
    //     try {
    //       const response = await axios.get(`user/setting/disc/view`);
    //       if (response.data.success) {
    //         setText(response.data.data.desc);
    //       } else {
    //         alert('error cancellation Policy api call')
    //       }
    //       console.log(response);
    //       setLoader(false);
    //     } catch (error) {
    //       console.log(error);
    //       setLoader(false);
    //     }
    //   }
    
    //  useEffect(() => {
    //     disclaimer();
    // },[])

    return (

        <div style={styles.bgBody}>
          {loader && loader ? <div className="position-loader"><Loader  /></div>  : 
          <div style={styles.bgBodyInner}>
            <div style={{backgroundColor: '#fff', height: '100%',}}>
            {/* <p style={styles.pageNameText}>Disclaimer</p> */}
            
            <div style={styles.bgCard}>
                <div  style={styles.aboutTextSection}>
                  <p>
                  For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-7 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. HILLMART VENTURES PRIVATE LIMITED is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-7 days rom the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 9394840857 or aivimart8@gmail.com
                  </p>
                  {/* <p>Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below</p> */}
                </div>
            </div>
            </div>

          </div>
          }
          {/* Divider */}
          <div style={styles.divider}></div>
         </div>
    );
};

export default Shipping;
const styles = {
  divider: { padding: 40 },
  bgBody:{backgroundColor:'#f1f3f6',height: '100%'},
  bgBodyInner: { paddingLeft:80,paddingRight:80,textAlign: 'justify',paddingTop:20},
  pageNameText:{fontSize:18, color: '#000000', fontWeight: 'bold',
  marginTop: 10,textAlign:'center',paddingTop:20 }, 
  bgCard: {
      marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5,borderColor: '#ddd',padding:10
  },
  aboutTextSection: { marginHorizontal: 10, padding:10},
}


