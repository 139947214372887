import React, { useState , useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Spinner  from 'react-bootstrap/Spinner';
import Cookies from 'js-cookie';
import axios from 'axios';
import OtpTimer from 'otp-timer';
import { encrpt  } from "../../components/Slider/encryption";
import AuthUser from '../../screen/context/AuthUser';
import UserNameContext from '../context/profile';

function Login() {
    const Auth = useContext(AuthUser);
    // console.log(Auth, 'auth');
    const navigate = useNavigate();
    const [auth , setAuth ] = useState();
    const [message  , setMessage ] = useState();
    const [counter , setCounter] = useState();
    const [counterVisible, setcounterVisible] = useState(false);
    const [sendBtnVisible, setSendBtnVisible] = useState(true);
    const [otpSendProgress, setOtpSendProgress] = useState(false);
    const [otpVerifyProgress, setOtpVerifyProgress] = useState(false);
    const [basicDetailsProgress, setBasicDetailsProgress] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [errors,setErrors]=useState();
    const [ newOtp, setNewOtp ] = useState();
    const [accountGender,setAccountGender] = useState('M');
    const [fieldVisibility,setFieldVisibility] = useState(0);
    const [data,setData]=useState({
      id:'',
      mobile:'',
      otp:'',
      name:'',
      isValidMobile: '',
      check_textInputChange: '',
    });
    const [ newUser , setNewUser ] = useState({
      mobile : data && data.mobile,
      gender : accountGender,
      name : ''
    })
    //  console.log(newUser.gender , 'accountGender');
    //   console.log(Auth , 'sendBtnVisible');
    const userName = useContext(UserNameContext);



      const reSendOtpHandle = () => {
          setCounter(10);
          sendOtpHandle(data.mobile);
        }

      const textInputChange = e =>{
        console.log('input', e);
        if (e.trim().length===10) {
         setData({...data,
           mobile:e,
           isValidMobile: true,
           check_textInputChange: true,

         })
        } else {
         setData({...data,
           mobile:e,
           isValidMobile: false,
           check_textInputChange: false,
     
         })
        }
       }

   const sendOtpHandle = async (mobile) => {
 
    setOtpSendProgress(true)
    try {
      const response = await axios.post(`/user/otp/send`, {mobile: mobile});
      console.log('response', response);
      if (response.data.success) {
        setFieldVisibility(1)
        setcounterVisible(true)
        setSendBtnVisible(false)
        setOtpSendProgress(false)
        setMessage(response.data.message);
      } else {
        console.log(response.data.message) 
        setOtpSendProgress(false)
      }
    } catch (error) {
      console.log(error);
      setOtpSendProgress(false)
    }
  };

  const selectOtp = (otp) => {
    setData({
      ...data,
      otp: otp,
    })
    setNewOtp(otp);
  }
  // console.log(Auth , 'AuthUser');
  

  const onchangeGender=(gender)=>{
    setAccountGender(gender)
    setNewUser({
        ...newUser,
        gender:gender,
    })
}

  const sendOtpVerify = async () => {
      setOtpVerifyProgress(true)
      try {
        const dataVerify ={
            mobile : data.mobile,
            otp : data.otp
          }
      console.log(dataVerify , 'data otp verify')
      const response = await axios.post(`user/otp/verify`,dataVerify )
      .then((response) => {
        if(response.data.token) {
          setOtpVerifyProgress(false);
          // console.log(auth , 'auth check');
          Auth.setAuth(true);
          let token = encrpt(response.data.token)
          // console.log(token,'token');
          Cookies.set('s_l', token, { expires: 3600 })
          navigate('../your-account/profile' );
          // , {state : { userData : response.data.data}}
          // console.log(Cookies , 'token check');
          // console.log(token,"id");
        }else {
          // console.log('otp response', response);
            setMessage(false);
            setFieldVisibility(2);
            setIsDisabled(true);
            setErrors(false);
            setOtpVerifyProgress(false);
          }
      })
      } catch (error) {
        setOtpVerifyProgress(false);
        // console.log(error,'catch');
        setErrors(error.response.data.errors)
        setMessage(error.response.data.message)
    }
  };

  
  const registerUser= async () => {
    setBasicDetailsProgress(true)
    try {
      const response = await axios.post(`user/registration/submit`,newUser);
      console.log(response,"basic details");
     if (response.data.success === true) {
       setBasicDetailsProgress(false);
       let token = encrpt(response.data.token)
        Cookies.set('s_l', token, { expires: 183 })
        Auth.setAuth(true);
       navigate('../your-account/profile' , {state : { userData : response.data.data}})
      //  console.log(newUser , 'newUser check add')
       userName.setUserName(newUser  && newUser.name)
     } else {
      setBasicDetailsProgress(false)
     }
    } catch (error) {
      setBasicDetailsProgress(false)
      // console.log(error,'catch');
      if (error.response.status==400) {
        // console.log(error.response.data.errors.otp,'catch')
        setErrors(error.response.data.errors);
      } else {
      
      }
    }
  }
 

  useEffect(() => {
    setNewUser({
      ...newUser,
        mobile : data ? data.mobile : ''
      })
  },[data]);

    return (
        <>
        <div className="login-page position-relative">
             {otpSendProgress &&
        <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100' , top : 0 , bottom : 0 , left : 0 , right : 0}}>
          <div style={styles.loader}>
              <div className="loaderInner">
                  <Spinner animation="border" size="xl" variant='warning'></Spinner>
              </div>
              <p>Sending OTP</p>
              <p style={{marginTop: -17}}>Please, wait...</p>
          </div>
        </div>
            }
            {otpVerifyProgress &&
                <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100' , top : 0 , bottom : 0 , left : 0 , right : 0}}>
                <div style={styles.loader}>
                    <div className="loaderInner">
                        <Spinner animation="border" size="xl" variant='warning'></Spinner>
                    </div>
                    <p>Verify OTP</p>
                    <p style={{marginTop: -17}}>Please, wait...</p>
                </div>
                </div> 
            }
            {basicDetailsProgress &&
                <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100' , top : 0 , bottom : 0 , left : 0 , right : 0}}>
                <div style={styles.loader}>
                    <div className="loaderInner">
                        <Spinner animation="border" size="xl" variant='warning'></Spinner>
                    </div>
                    <p>Adding Details</p>
                </div>
                </div> 
            }
            <div className='login-outer'>
                <div>
                    {/* <img src={require('../../assets/logo.png')} className="" alt="logo" /> */}
                </div>
                <div className='login-inner'>
                    <div><h2 style={{color : '#fdc624'}} >Sign in</h2></div>
                    <form action="" method="POST">
                        <div className='login-id'>
                            <label>Mobile phone number</label>
                            <input type="text" maxLength="10" disabled={isDisabled} onChange={e => textInputChange(e.target.value)}  required />
                                {
                                data.check_textInputChange == true ? 
                                <div style={{marginBottom : 20}}>
                                    {counterVisible &&
                                    <div className="otp-btn" style={{marginBottom : '28px'}}>
                                        <OtpTimer 
                                        seconds= {counter}   buttonColor={"#000"}   backgroundColor={"#8f8f8f"} textColor={"#fff"} ButtonText="Resend Otp"
                                        text="Resend Otp in :"  minutes={0} resend={() => reSendOtpHandle()}
                                        />
                                    </div>
                                    }
                                    {sendBtnVisible &&
                                        // <button className="registration-otp-verify-btn" type="button" >Send Otp</button>
                                        <div className='otp-btn'><button type="button" onClick={()=>sendOtpHandle(data.mobile)}>Send OTP</button></div>
                                    }
                                </div>:null
                                }
                                {( fieldVisibility >=1) && (
                                    <div className="col-md-12 position-relative" style={{marginBottom:20}}>
                                        <label className="form-label label-txt col-md-7">Enter OTP</label>
                                        <input type="text" disabled={isDisabled} className="form-control" placeholder="Enter Otp" onChange={otp => selectOtp(otp.target.value)} required/>
                                        <button className="otp-btn-2" type="button" onClick={()=>sendOtpVerify()}>Verify Otp</button>
                                        {errors && errors.otp && (<p style={{color: 'red',fontSize:12 , position : 'absolute'}}>{errors.otp}</p>)}
                                        {message && message && (<p style={{color: 'green',fontSize:12 , position : 'absolute',marginTop: 3,left: 8}}>{message}</p>)}
                                    </div>
                                    )
                                }
                                {( fieldVisibility >=2) && (
                                    <div style={{marginBottom:20}}>
                                    <div className="col-md-12">
                                        <label className="form-label label-txt">Your name</label>
                                        <input type="text" className="form-control " onChange={e=>setNewUser({...newUser, name:e.target.value})} required/>
                                        {errors && errors.name && (<p style={{color: 'red',fontSize:12}}>{errors.name}</p>)}
                                    </div>
                                    <div className='mt-3 mb-3'>
                                      <label>Gender</label>
                                      <div className="d-flex">
                                        <div className="form-check">
                                            <input type="radio" value="M" name="gender" checked={accountGender == 'M'} onChange={(e) => onchangeGender(e.currentTarget.value)}/> Male
                                            <input type="radio" value="F" name="gender" checked={accountGender == 'F'} onChange={(e) => onchangeGender(e.currentTarget.value)} style={{marginLeft: 20}} /> Female
                                          </div>
                                      </div>
                                    </div>
                                    <button className="col-md-12 mt-1 btn-primary" type="button" onClick={()=>registerUser()}>Continue</button>
                                    </div>
                                    )
                                }
                        </div>
                    </form>
                    {/* <button className='btn-primary' >Continue</button> */}
                    <div className='mt-2'>
                        <small>By continuing, you agree to Aivimart's Conditions of Use and Privacy Notice.</small>
                    </div>
                </div>
                {/* <div className='register-section'>
                    <div className="a-divider a-divider-break"><h5>New to Jivimart?</h5></div>
                </div>
                <div style={{width : '100%'}} className='btn-secondary'>
                    <span>
                        <Link to="/register"  >Create your Jivimart Account</Link>
                    </span>
                </div> */}
            </div>
        </div>
      </>
    );
} 

export default Login;
const styles = {
    loader:{position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},
  }