import React, { useEffect, useState, useContext, useCallback } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Homepage from '../screen/homePage/';
import Product from '../screen/product/';
import Login from "../screen/login";
import Register from "../screen/register/register";
import ProductDetail from '../screen/product/detail';
import ProductsGrid from "../screen/product/products";
import ProductSearch from '../screen/product/productSearch';
import Header from '../screen/include/header';
import Footer from '../screen/include/footer';
import Contact from '../screen/contact/';
import Cart from '../screen/cart/';
import Checkout from '../screen/checkout/';
import Confirm from '../screen/checkout/confirm';
import Account from '../screen/account'
import Profile from '../screen/account/profile/';
import Orders from '../screen/account/order/';
import OrdersDetail from '../screen/account/order/detail';
import Address from '../screen/account/address/';
import Wishlist from '../screen/account/wishlist/';
import axios from "axios";
import Loader from "../components/Slider/loader";
import AuthUser from "../screen/context/AuthUser";
import Cookies from "js-cookie";
import UserNameContext from "../screen/context/profile";
import AddWishListContext from '../screen/context/AddWishList';
import CountWishlistCartContext from '../screen/context/CountWishListCart';
import { decrpt } from "../components/Slider/encryption";
import ProductsGridTradional from "../screen/product/tradional";
import { Nomatch } from "../screen/404";
import Disclaimer from '../screen/additional/disclaimer';
import AboutUs from '../screen/additional/aboutUs';
import BankDetails from '../screen/account/order/bankDetails';
import MyForm from "../screen/francise/page";
import BankInformation from "../screen/account/bankstuff";
// import Shipping from "../screen/additional/Shipping";
import RefundAndReturn from "../screen/additional/RefundAndReturn";
// import CancellationPolicy from '../screen/additional/cancellationPolicy';
// import PrivacyPolicy from '../screen/additional/privacyPolicy';
// import TermsCondition from '../screen/additional/termsCondition';
// import ContactUs from '../screen/additional/contactUs';
import ContactUs from '../screen/contactUs';
import Shipping from "../screen/Shipping";
import PrivacyPolicy from '../screen/privacyPolicy';
import TermsCondition from '../screen/termsCondition';
import CancellationPolicy from '../screen/cancellationPolicy';


function MainNavigation(width) {
  // const Navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState();
  const [sliders, setSliders] = useState();
  const [appLoadData, setAppLoadData] = useState();
  const [productDetails, setProductDetails] = useState();
  const [auth, setAuth] = useState();
  const [initialRoute, setInitialRoute] = useState(null);
  const [UserName, setUserName] = useState();
  const [profileName, setProfileName] = useState();
  const [countWishCart, setCountWishCart] = useState();
  const [targetReached, setTargetReached] = useState(false)


  console.log(countWishCart, 'countWishCart countWishCart');


  const readCookie = () => {
    const initialRouteNew = Cookies.get('i_r');
    const token = Cookies.get('s_l');
    if (token && token.length > 15) {
      setAuth(true);
      console.log(auth, ' auth chekc');
    }
    if (initialRouteNew) {
      setInitialRoute(initialRouteNew);
    } else {
      setInitialRoute(initialRouteNew);
    }
  }

  // console.log(auth , 'check auth navigation')
  // ---------------------- api call --------------------------------------//
  // const appLoad = async() => {
  //   setLoader(true);
  //   try {
  //     const response = await axios.get(`/user/app/load`)
  //      .then((response) => {
  //       console.log(response,'app load');
  //       if(response.data.success){
  //           setCategories(response.data.categories);
  //           setSliders(response.data.sliders);
  //           setAppLoadData(response.data);
  //       // console.log(response.data);
  //       setLoader(false);
  //       }
  //     })
  //   }catch(error){
  //     console.log(error , 'error');
  //     setLoader(false);
  //   }
  // }
  // const Count = useContext(CountWishlistCartContext);

  const appLoad = async () => {
    setLoader(true);
    console.log(auth, 'auth auth');
    if (auth && auth === true) {
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`/user/app/load`)
          .then((response) => {
            // console.log(response,'app load');
            if (response.data.success) {
              setCategories(response.data.categories);
              setSliders(response.data.sliders);
              setAppLoadData(response.data);
              setProfileName(response.data.user_name);
              let CountNo = response.data;
              setCountWishCart(CountNo);
              // console.log(response.data , 'profileName c');
              setLoader(false);
            }
          })
      } catch (error) {
        console.log(error, 'error 1');
        setLoader(false);
      }
      console.log(auth && auth, 'authauth if');
    } else {
      try {
        const response = await axios.get(`/user/app/load`)
          .then((response) => {
            console.log(response.data, '9999999999');
            if (response.data.success) {
              setCategories(response.data.categories);
              setSliders(response.data.sliders);
              setAppLoadData(response.data);

              setLoader(false);
            }
          })
        console.log(auth && auth, 'authauth else');
      } catch (error) {
        // console.log(error , 'error 2');
        setLoader(false);
      }
    }

  }

  console.log(profileName && profileName, 'profileName profileName')

  const productDetailsFetch = async (item) => {
    setLoader(true);
    console.log()
    try {
      const response = await axios.post(`/user/product/details`, { product_id: item })
        .then((response) => {
          if (response.data.success) {
            setProductDetails(response.data)
            console.log("789987", response.data)
            setLoader(false);
          }
        })
    } catch (error) {
      // console.log(error , 'error');
      setLoader(false);
    }
  }

  // product List With Filter Mandatery category with pagination

  // ---------------------- api call --------------------------------------//
  // ------------------------ use effect ----------- //

  useEffect(() => {
    readCookie();
    window.scrollTo(0, 0);
    appLoad(auth && auth);
    console.log(window.location, 'huboi');
  }, [auth && auth]);
  // ------------------------ use effect ------------------------ //


  return (
    <>
      {
        loader && loader ? <Loader /> :
          <>
            <BrowserRouter>
              <AuthUser.Provider value={{ auth, setAuth }} >
                <CountWishlistCartContext.Provider value={{ countWishCart, setCountWishCart }}>
                  <UserNameContext.Provider value={{ UserName, setUserName }}>
                    <Header state={categories} productDetailsFetch={productDetailsFetch} productDetails={productDetails} profileName={profileName} appLoadData={appLoadData} />
                    {/* <initialRoute.Provider value={{initialRoute,setInitialRoute}}> */}
                    {/* <UserNameContext.Provider value={{UserName,setUserName}}> */}
                    <Routes>
                      <Route path="/" element={<Homepage state={appLoadData} />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/product/ProductsGridTradional" element={<ProductsGridTradional />} />
                      <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
                      <Route path="/additional/disclaimer" element={<Disclaimer />} />
                      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                      {/* <Route path="/additional/terms_condition" element={<TermsCondition />} /> */}
                      <Route path="/terms_condition" element={<TermsCondition />} />
                      <Route path="/contactUs" element={<ContactUs />} />
                      <Route path="/additional/aboutUs" element={<AboutUs />} />
                      <Route path="/Shipping" element={<Shipping />} />
                      <Route path="/additional/RefundAndReturn" element={<RefundAndReturn/>} />


                      <Route path="/product" element={<Product />} >
                        <Route index exact path="/product/productgrid/:id" element={<ProductsGrid />} />
                        <Route path="/product/product-detail/:id/:name" element={<ProductDetail />} />
                        <Route path="/product/productSearch" element={<ProductSearch />} />
                      </Route>

                      <Route path="/cart" element={<Cart />} />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/Confirm/:orderidcookie" element={<Confirm />} />
                      <Route path="your-account" element={auth && auth == true ? <Account /> : <Login />} >
                        <Route index exact path={"/your-account/profile"} element={auth && auth == true ? <Profile /> : <Login />} />
                        <Route path="/your-account/orders" element={auth && auth == true ? <Orders /> : <Login />} />
                        <Route path="/your-account/orders/detail" element={auth && auth == true ? <OrdersDetail /> : <Login />} />
                        <Route path="/your-account/orders/bankDetails" element={auth && auth == true ? <BankDetails /> : <Login />} />
                        <Route path="/your-account/address/" element={auth && auth == true ? <Address /> : <Login />} />
                        <Route path="/your-account/wishlist/" element={auth && auth == true ? <Wishlist /> : <Login />} />
                        <Route path="/your-account/bankDetails" element={auth && auth == true ? <BankInformation /> : <Login />} />
                      </Route>
                      <Route path="/franchise" element={<MyForm />} />
                      <Route path="*" element={<Nomatch />} />
                    </Routes>


                    {/* </UserNameContext.Provider> */}
                  </UserNameContext.Provider>
                  {/* </initialRoute.Provider> */}
                </CountWishlistCartContext.Provider>
              </AuthUser.Provider>
              <Footer />
            </BrowserRouter>
          </>
      }
    </>
  );
}

export default MainNavigation;
