import React, { useEffect, useState,useContext } from "react";
import { Link, Outlet ,useNavigate } from "react-router-dom";
import MainSlider from '../../components/Slider/homeSlider';
import Categoryslider from '../../components/Slider/categorySlider';
import MostSellingSlider from "../../components/Slider/mostSellingSlider";
import BestDeal from "../../components/Slider/bestDeal";
import Productslider from '../../components/Slider/productSlider';
// import { AiOutlineShopping,AiOutlineHeart } from "react-icons/ai";
// import axios from "axios";
import loader from "../../components/Slider/loader";
import AddWishListContext from '../context/AddWishList';
// import Cookies from "js-cookie";
// import { decrpt } from "../../components/Slider/encryption";

function Homepage(props) {  
  const [loader , setLoader ] = useState(true); 

  const Wishlist = useContext(AddWishListContext);
  const navigate = useNavigate();
  let latestproduct = props.state &&  props.state.latest_products;
  let featured = props.state && props.state.featured_products;
  let most_selling = props.state && props.state.most_selling_product;
  let best_deal = props.state && props.state.best_deals  ;
  let thirdCategories = props.state && props.state.third_categories  ;
  let categories = props.state && props.state.categories

  console.log(props.state && props.state , 'smclkmscklm')


  const productListfetch = (item ) => {
    navigate(`/product/productgrid/${item}`, { state: { item } })

 }


  // const addWishList = async(id)=>{
  //   try {
  //     let data = {
  //       product_id: id
  //   };
  //     console.log(data);
  //     let token = decrpt(Cookies.get('s_l'));
  //     console.log(token,'token token');
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //     const response = await axios.post(`/user/wishlist/add`,data);
  //     console.log(response,'response wishlist');
  //     if (response.data.success) {
  //       Wishlist.setAddWishList(response.data.data);
  //     } else {
        
  //     }
      
  //   } catch (error) {
  //     console.log(error,'error');
  //   }
  // }


  useEffect (() => {
    window.scrollTo(0, 0);
    setLoader(true);
  }, []);
  return (
    <div className="responsive-xs responsive-sm">
      {/* HomeSlider */}
      <MainSlider 
      props={props}
      // slideImages={slideImages}
       />

      {/* Category */}      
      <section className="homeCategory">
        <div className="container">
          <div className="section-title">
            <h4>Check out the most popular categories</h4>
          </div>
          <Categoryslider
            thirdCategories={thirdCategories}
          />
        </div>
      </section>

      {/* ProductSlider */}    
      <section className="homeProduct">
        <div className="container">
          <div className="section-title">
            <h4>Latest Products</h4>
            {/* <a className="viewAllBtn">View All</a> */}
          </div>
          <Productslider 
            latestproduct={latestproduct} 
          />
        </div>
      </section>

      {/* Special Banner */}      
      <section>
        <div className="container">
          <div className="delivery">
            <div className="delivery__content">
                <div className="delivery__text"> <i className="icon-shield-check"></i><span> <strong>100% Secure delivery </strong>to our customer</span></div><a className="delivery__more" href="#">More</a>
            </div>
          </div>
        </div>
      </section>

      {/* ProductSlider */}    
      <section className="homeProduct homeProductSpecial">
        <div className="container">
          <div className="section-title">
            <h4>Best Deals of the Week!</h4>
            {/* <a className="viewAllBtn">View All</a> */}
          </div>
          <BestDeal
            best_deal={best_deal}
          />
        </div>
      </section>

      {/* MostSoldProductSlider */}    
      <section className="homeProduct bg-white">
        <div className="container">
          <div className="section-title">
            <h4>Most Sold Product</h4>
            {/* <a className="viewAllBtn">View All</a> */}
          </div>
          <MostSellingSlider 
            most_selling={most_selling}          
          />
        </div>
      </section>

      {/* FetureProductSlider */}   
      <>
        {/* <section className="homeProduct fetureProduct bg-white">
          <div className="container">
            <div className="section-title">
              <h4>Featured Product</h4>
              <a className="viewAllBtn">View All</a>
            </div>
            <div className="featureBlock">
              {featured && featured ? featured.map((item,index) => {
                return (
                  <div key={index} to="product/product-detail" >
                  <Link state={item} to="product/product-detail"> 
                  <div className="product__image" >
                    <img src={item.main_image} alt="" />
                  </div>
                  </Link>
                  <div className="product__actions">
                        <div className="product__item"><button type="button">
                          <AiOutlineShopping />
                          </button></div>
                        <div className="product__item"><button type="button">
                          <AiOutlineHeart />
                          </button></div>
                    </div>
                  <Link state={item} to="product/product-detail"> 
                    <div className="product__content">
                      <div className="product__title" >{item.name}</div>
                      <div className="product__meta">
                        <span className="product__price sale">₹{item.price}</span><span className="product__del">₹{item.mrp}</span>
                      </div>
                    </div>
                  </Link>
                </div>
                );
              }) : <> </>}    
            </div>
          </div>
        </section> */}
      </> 

      {/* Bottom Services */}
      <section className="homeProduct bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="bot-block">
                <div className="icon-bot">  
                  <img src="images/icon/medal.png" />
                </div>
                <div className="bot-content">
                  <h5>Genuine Product</h5>
                  <p>Aivimart Offers You High Quality Genuine Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bot-block">
                <div className="icon-bot">  
                  <img src="images/icon/customer-service-agent.png" />
                </div>
                <div className="bot-content">
                  <h5>24/7 Customer Support</h5>
                  <p>Aivimart Customer Support is 24/7 Available</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bot-block">
                <div className="icon-bot">  
                  <img src="images/icon/shipped.png" />
                </div>
                <div className="bot-content">
                  <h5>Fast Delivery</h5>
                  <p>Aivimart Delivery Partners Offers you Fast delivery on All of your Products</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Footer Category */}
      <section className="categoryArea" style={{background:' #f6f5f5'}} >
        <h1>Top Category on <span style={{color: '#FD8D27'}}>Aivimart</span></h1>
        {
          categories && categories ? categories.map((item,index) => {
            return (
              <div key={index} className="category-list-block">
                  <span>{item.name}  : </span>
                  {item && item.sub_category.map((item1, index1) => {
                    return (
                      // <span>{item1.name}</span>
                      <>
                        {
                          item1 && item1.last_category.map((item2, index2) => {
                            return (
                              <a style={{cursor : 'pointer'}} onClick={()=> productListfetch(item2.id)}>{item2.name}</a>
                            );
                          })
                        }
                      </>
                    );
                  })}
              </div>
            );
          }) : ''

        }
      </section>

      {/* Terms & Conditions */}
      <section className="termsArea">
        <h2>Aivimart: The One-stop Shopping Destination</h2>
        E-commerce is revolutionizing the way we all shop in India. Why do you want to hop from one store to another in search of the latest phone when you can find it on the Internet in a single click? Not only mobiles. Aivimart houses
        everything you can possibly imagine, from trending electronics like laptops, tablets, smartphones, and mobile accessories to in-vogue fashion staples like shoes, clothing and lifestyle accessories; from modern furniture like sofa sets,
        dining tables, and wardrobes to appliances that make your life easy like washing machines, TVs, ACs, mixer grinder juicers and other time-saving kitchen and small appliances; from home furnishings like cushion covers, mattresses and
        bedsheets to toys and musical instruments, we got them all covered. You name it, and you can stay assured about finding them all here. For those of you with erratic working hours, Aivimart is your best bet. Shop in your PJs, at night or
        in the wee hours of the morning. This e-commerce never shuts down. <br />
        <h2>What Can You Buy From Aivimart?</h2>
        A world of limitless possibilities awaits you.<br />
        <br />
        
        <h2>Mobile Phones</h2>
        From budget phones to state-of-the-art smartphones, we have a mobile for everybody out there. Whether you're looking for larger, fuller screens, power-packed batteries, blazing-fast processors, beautification apps, high-tech selfie cameras or just large internal space, we take care of all the essentials. Shop from top brands in the country like Samsung, Apple, Oppo, Xiaomi, Realme, Vivo, and Honor to name a few. Rest assured, you're buying from only the most reliable names in the market.  <br />
        <br />
        <h2>Electronic Devices and Accessories</h2>
        When it comes to laptops, we are not far behind. Filter among dozens of super-fast operating systems, hard disk capacity, RAM, lifestyle, screen size and many other criterias for personalized results in a flash. All you students out
        there, confused about what laptop to get? Our <b>Back To College Store</b> segregates laptops purpose wise (gaming, browsing and research, project work, entertainment, design, multitasking) with recommendations from top brands and
        industry experts, facilitating a shopping experience that is quicker and simpler.<br />
        <br />
        Photography lovers, you couldn't land at a better page than ours. Cutting-edge DSLR cameras, ever reliable point-and-shoot cameras, millennial favourite instant cameras or action cameras for adventure junkies: our range of cameras is as
        much for beginners as it is for professionals. Canon, Nikon, GoPro, Sony, and Fujifilm are some big names you'll find in our store. Photography lovers, you couldn't land at a better page than ours. Cutting-edge DSLR cameras, ever
        reliable point-and-shoot cameras, millennial favourite instant cameras or action cameras for adventure junkies: our range of cameras is as much for beginners as it is for professionals. Canon, Nikon, GoPro, Sony, and Fujifilm are some
        big names you'll find in our store. <br />
        <br />
        Turn your home into a theatre with a stunning surround sound system. Choose from our elaborate range of Sony home theatres, JBL soundbars and Philips tower speakers for an experience to remember.<br />
        <br />
        How about jazzing up your phone with our quirky designer cases and covers? Our wide-ranging mobile accessories starting from headphones, power banks, memory cards, mobile chargers, to selfie sticks can prove to be ideal travel
        companions for you and your phone; never again worry about running out of charge or memory on your next vacation.<br />
        <br />
        <h2>Large Appliances</h2>
        Sleek TVs, power-saving refrigerators, rapid-cooling ACs, resourceful washing machines - discover everything you need to run a house under one roof. Our <b>Dependable TV and Appliance Store</b> ensures zero transit damage, with a
        replacement guarantee if anything goes wrong; delivery and installation as per your convenience and a double warranty (Official Brand Warranty along with an extended Aivimart Warranty) - rest assured, value for money is what is promised
        and delivered. Shop from market leaders in the country like Samsung, LG, Whirlpool, Midea, Mi, Vu, Panasonic, Godrej, Sony, Daikin, and Hitachi among many others. <br />
        <br />
        <h2>Small Home Appliances</h2>
        Find handy and practical home appliances designed to make your life simpler: electric kettles, OTGs, microwave ovens, sandwich makers, hand blenders, coffee makers, and many more other time-saving appliances that are truly crafted for a
        quicker lifestyle. Live life king size with these appliances at home.<br />
        <br />
        <h2>Lifestyle</h2>
        Aivimart, <b>'India ka Fashion Capital'</b>, is your one-stop fashion destination for anything and everything you need to look good. Our exhaustive range of Western and Indian wear, summer and winter clothing, formal and casual
        footwear, bridal and artificial jewellery, long-lasting make-up, grooming tools and accessories are sure to sweep you off your feet. Shop from crowd favourites like Vero Moda, Forever 21, Only, Arrow, Woodland, Nike, Puma, Revlon, Mac,
        and Sephora among dozens of other top-of-the-ladder names. From summer staple maxi dresses, no-nonsense cigarette pants, traditional Bandhani kurtis to street-smart biker jackets, you can rely on us for a wardrobe that is up to date.
        Explore our in-house brands like Metronaut, Anmi, and Denizen, to name a few, for carefully curated designs that are the talk of the town. Get ready to be spoiled for choice.Festivals, office get-togethers, weddings, brunches, or
        nightwear - Aivimart will have your back each time.<br />
        <br />
        <h2>Home and Furniture</h2>
        Moving to a new place is never easy, especially if you're buying new furniture. Beds, sofa sets, dining tables, wardrobes, and TV units - it's not easy to set up everything again. With the hundreds of options thrown at you, the ride
        could be overwhelming. What place is reliable, what furniture will stand the test of time? These are questions you must ask before you choose a store. Well, our <b>Durability Certified Furniture Store</b> has not only curated a range of
        furniture keeping in mind the modern Indian consumer but furniture that comes with a lab certification, ensuring they last you for up to 10 years. Yes, all our furniture has gone through 35 stability and load tests so that you receive
        only the best-quality furniture. <b>Be FurniSure</b>, always. Names to look out for are Nilkamal, Godrej Interio, Urban Ladder, HomeTown, Durian and Perfect Homes. <br />
        <br />
        You may have your furniture all set up, but they could end up looking flat and incomplete without complementary decor. Curtains, cushion covers, bed sheets, wall shelves, paintings, floor lamps - find everything that turns a house to an
        inviting home under one roof at Aivimart. <br />
        <br />
        <h2>Baby and Kids</h2>
        Your kids deserve only the best. From bodysuits, booties, diapers to strollers, if you're an expecting mother or a new mother, you will find everything you need to set sail on a smooth parenting journey with the help of our baby care
        collection. When it comes to safety, hygiene and comfort, you can rely on us without a second thought. Huggies, Pampers, MamyPoko, and Johnson &amp; Johnson: we host only the most-trusted names in the business for your baby.<br />
        <br />
        <h2>Books, Sports and Games</h2>
        Work hard and no play? We don't believe in that. Get access to best-selling fiction and non-fiction books by your favourite authors, thrilling English and Indian blockbusters, most-wanted gaming consoles, and a tempting range of fitness
        and sports gadgets and equipment bound to inspire you to get moving. <br />
        <br />
      </section>

      
      <Outlet />
    </div>
  );
}

export default Homepage;
