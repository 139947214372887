import React from 'react';

const BankDetails = () => {
    return (
        <div>
            <main style={{paddingLeft: 80,paddingRight: 80,textAlign: 'justify', paddingTop: 20,paddingBottom:20}}>
                <div className="bank-div">
                    <div class="account-main-inner position-relative ">
                        <div class="area-title"><h4>Fill Bank Details</h4></div>
                    </div>
                    <div>
                    <div className="address-line mt-3">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    <div className="address-line">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    <div className="address-line">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    <div className="address-line">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    <div className="address-line">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    <div className="address-line">
                        <label>Mobile Phone</label>
                        <input type="text" name="phone" className="form-control" placeholder=" Enter Mobile text" />
                    </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BankDetails;