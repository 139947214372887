import * as React from "react";

function Contact() {
  return (
    <div>
      <h1>This is Contact</h1>
    </div>
  );
}

export default Contact;
