import React, {useState , useEffect , useContext}  from "react";
import { Link  , useNavigate} from "react-router-dom";
import '../../App.css';
import { FiShoppingCart, FiUser, FiHeart, FiSearch, FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import { BiUserPin, BiArchive, BiBookmarkHeart, BiPowerOff, BiMessageError, BiHomeSmile, BiRupee ,BiLibrary } from "react-icons/bi";
import { AiOutlineShop,AiOutlineLogin } from "react-icons/ai";
import { BsCursor, BsBorderAll } from "react-icons/bs";
import { MdOutlineLocalOffer } from "react-icons/md";
import AuthUser from "../context/AuthUser";
import CountWishlistCartContext from '../context/CountWishListCart';
import axios from "axios";
import Cookies from "js-cookie";
import UserNameContext from "../context/profile";
import Loader from "../../components/cartSummaryLoader";
import Collapsible from 'react-collapsible';




function Header(props) {
    const textInput = React.useRef();
    let propsData = props.state;
    // const appLoadData = props.appLoadData;
    const navigate = useNavigate();
    const Auth = useContext(AuthUser);
    const [ openMenu, seOpenMenu] = useState(false);
    const [ paginate , setPaginate] = useState();
    const [ isActivePro, setActivePro] = useState(false);
    const [ isActivePro2, setActivePro2] = useState(false);

    const Count = useContext(CountWishlistCartContext);
    console.log(props,'wishlist count props');

    const [searchData, SetSearchData] = useState({
        search_key:'',
    });

    const [loader , setLoader ] = useState();
  let usernameProfile  = useContext(UserNameContext);
    console.log(usernameProfile , 'check user name')
    const openProduct = () => {
        if (isActivePro == true) {
            setActivePro(false);
        }else{
            setActivePro(true);
        }
    }
    const openProduct2 = () => {
        if (isActivePro2 == true) {
            setActivePro2(false);
        }else{
            setActivePro2(true);
        }
    }
    
    const logOutHandler = () => {     
        Auth.setAuth(false);
        Cookies.remove('s_l');
        navigate('/login');
      }

    const productListfetch = (item ) => {
        
       navigate(`/product/productgrid/${item}`, { state: { item } })
    }

    const clearInput = () => (textInput.current.value = "");

    const searchProductData = async () => {
        setLoader(true)
        try {
                const response = await axios.post(`/user/product/search`, searchData && searchData);
                // console.log('searchProductData', response.data)
                if (response.data.success == true) {
                    let searchDataArray = response.data.data.list ;
                    await navigate('product/productSearch',{state:{searchDataArray:searchDataArray,loader:loader}} );
                    setLoader(false)
                    clearInput();
                } else {
                    setLoader(false)
                }
            } catch (error) {
                // console.log(error);
                setLoader(false)
            }
       
    }
   
    useEffect (() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <nav className="responsive-xs">
        <div className="container">
            {/* Top Navbar */}
            <div className="navbar">
                <div className="comp-logo">
                    <Link to="/"><img src={require('../../assets/logo.png')} className="" alt="logo" /></Link>
                    <button className="mble-menu-btn" onClick={() => seOpenMenu(true)}><FiMenu /></button>
                </div>
                <div className="search-table">
                    <div className="input-group">
                        <input className="form-control input" type="text" placeholder="Search for all products for men, women" ref={textInput}  onChange={e=> SetSearchData({...searchData ,  search_key:e.target.value})}/>

                        <button type='button' className="input-group-append" onClick={()=>searchProductData()}><FiSearch /></button>
                    </div>
                </div>
                <div className="navbar-account">
                    <ul className="icon-holder">
                        <li className="icon account-main-icon">
                            <FiUser style={{color:"red"}} />
                            <div className="account_dropdown">
                                <ul>
                                    <li><Link to="/your-account/profile"><BiUserPin className="accountIcon" /> My Profile</Link></li>
                                    <li><Link to="/your-account/orders"><BiArchive className="accountIcon" /> My Orders</Link></li>
                                    <li><Link to="/your-account/address"><BsCursor className="accountIcon" /> My Address</Link></li>
                                    <li><Link to="/your-account/wishlist"><BiBookmarkHeart className="accountIcon" /> My Wislist</Link></li>
                                    <li><a href="https://seller.aivimart.com/"><AiOutlineShop className="accountIcon" /> Sell on Aivimart</a></li>
                                    {Auth.auth && Auth.auth ? 
                                        <li><a onClick={()=>logOutHandler()}>
                                            <BiPowerOff className="accountIcon" /> Logout</a>
                                        </li>:
                                        <li><Link to="/login"><AiOutlineLogin className="accountIcon" /> Login</Link></li>
                                    }
                                </ul>
                            </div>
                        </li>
                        <li className="iconCart"><Link to="/your-account/wishlist"><FiHeart />
                        {Count.countWishCart && Count.countWishCart.wishlist == null ? 
                        <span className="badgeCustom">0</span>:
                        <span className="badgeCustom">{Count.countWishCart && Count.countWishCart.wishlist}</span>
                        }
                        {/* <span className="badgeCustom">{appLoadData && appLoadData.cart}</span> */}
                        </Link>
                        </li>

                        <li className="iconCart"><Link to="/cart"><FiShoppingCart />
                        {Count.countWishCart && Count.countWishCart.cart == null ? 
                        <span className="badgeCustom">0</span>:
                        <span className="badgeCustom">{Count.countWishCart && Count.countWishCart.cart}</span>
                        }
                        </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="navbar-bottom">
            {/* <div className="container">
                <ul className="navbar-block">
                    <li className="navbar-list mega-menu">All Product<FiChevronDown className="drop-icon" />
                        <div className="mega-menu-inner">
                            <ul>
                            {props.state && props.state.length > 0 ?
                                <>
                                {props.state && props.state.map((item,index)=>{
                                    return(
                                        <li key={index}>
                                        <h5>{item.name}</h5>
                                        <div className="mega-submenu">
                                            <ul>
                                                {item && item.sub_category.map((item1,index1)=>{
                                                        return(
                                                            <>
                                                                <li  key={index1} className="sub-head"><h5>{item1.name}</h5></li>
                                                                {item1 && item1.last_category.map((item2,index2)=>{
                                                                return(
                                                                    <li key={index2}> 
                                                                    <button style={{cursor : 'pointer '}} type="button" onClick={()=> productListfetch(item2.id)}
                                                                        > {item2.name}</button></li>
                                                                        )
                                                                    })
                                                                }  
                                                              </>
                                                            )
                                                        })
                                                    }  
                                            </ul>
                                        </div>
                                    </li> 
                                    )
                                })}

                                </>:
                                 <li> No categories found</li>
                                 }
                            </ul>
                        </div>
                    </li>
                    <li className="navbar-list">Your Jivi</li>
                    <li className="navbar-list">Today's Deals</li>
                    <li className="navbar-list"> <Link to="/product/ProductsGridTradional"> Traditional</Link>
                    </li>
                    <li className="navbar-list">Jivi Pay</li>
                    <li className="navbar-list"> <a href="https://www.seller.jivimart.com/">  Sell</a></li>
                    <li className="navbar-list">Customer Service</li>
                </ul>
            </div> */}
             <div className="container">
                <ul className="navbar-block">
                    {
                        props.state?.map((item,index) => {
                            return(
                            <li key={index} className="navbar-list mega-menu">
                                <h5>{item.name}<FiChevronDown className="drop-icon" /></h5>
                                <div className="mega-submenu">
                                    <ul>
                                        {item && item.sub_category.map((item1,index1)=>{
                                            return(
                                                <>
                                                {item.sub_category && item.sub_category.length > 0 ?
                                                            <> 
                                                                <li  key={index1} className="sub-head"><h5>{item1.name}</h5></li>
                                                                {item1 && item1.last_category.map((item2,index2)=>{
                                                                return(
                                                                    <li key={index2}> 
                                                                    <button style={{cursor : 'pointer '}} type="button" onClick={()=> productListfetch(item2.id)}
                                                                        > {item2.name}</button></li>
                                                                        )
                                                                    })
                                                                }  
                                                            </>
                                                    : ''
                                                    }
                                                    </>
                                                )
                                            })
                                            }  
                                    </ul>
                                </div>
                            </li>
                            );
                        })
                    }
                    {/* <li className="navbar-list mega-menu">All Product<FiChevronDown className="drop-icon" /></li>
                    <li className="navbar-list">Your Jivi</li>
                    <li className="navbar-list">Today's Deals</li>
                    <li className="navbar-list"> <Link to="/product/ProductsGridTradional"> Traditional</Link>
                    </li>
                    <li className="navbar-list">Jivi Pay</li>
                    <li className="navbar-list"> <a href="https://www.seller.jivimart.com/">  Sell</a></li>
                    <li className="navbar-list">Customer Service</li> */}
                </ul>
            </div>
        </div>


        {/* ---------------------------------------------------Mobile Navbar--------------------------------------------------- */}



        {openMenu && 
        <div className="mobileNavbar">
            <div className="mobileNavbarInner">
                <button className="mble-menu-close" onClick={() => seOpenMenu(false)}><FiX /></button>
                {/* <div className="menu-comp-logo">
                    <img src={require('../../assets/logo.png')} />
                </div> */}
                <div className="menu-user-section">
                    <div className="user-info">
                        <img src={require('../../assets/man.png')} alt="profile" />
                        <h6>{props && props.profileName ? props.profileName : 'Guest'}</h6>
                    </div>
                </div>
                <div className="menu-all">
                    <div className="menu-group">
                        <div className="menu-list">
                            <Link to="/">
                                <BiHomeSmile className="menu-list-icon"/>
                                <span>Home</span>
                            </Link>                        
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/product/ProductsGridTradional">
                                <BiLibrary className="menu-list-icon"/>
                                <span>Traditional</span>
                            </Link>                        
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-list">
                            <div style={{position: 'relative'}} onClick={() => openProduct()}>
                                <BsBorderAll className="menu-list-icon" /> 
                                <span>All Product</span>
                                <FiChevronDown className={isActivePro ? 'droperIcon yes-rotate': 'droperIcon'} />
                            </div>
                            <div className={isActivePro ? 'mobile-menu-inner is-open': 'mobile-menu-inner'}>
                                <ul>
                                {props.state && props.state.length > 0 ?
                                <>
                                {props.state && props.state.map((item,index)=>{
                                    return(
                                        <li key={index}>
                                        {/* <h5  className="categoryClass"  onClick={() => openProduct2()}>{item.name}</h5> */}
                                        <Collapsible transitionTime={100} trigger={item.name}>
                                            <ul>
                                                {item && item.sub_category.map((item1,index1)=>{
                                                    return(
                                                        <>
                                                            <li  key={index1} className="sub-head"><h5>{item1.name}</h5></li>
                                                            {item1 && item1.last_category.map((item2,index2)=>{
                                                            return(
                                                                <li key={index2}> 
                                                                <button style={{cursor : 'pointer '}} type="button" onClick={()=> productListfetch(item2.id)}> {item2.name}</button></li>
                                                                    )
                                                                })
                                                            }  
                                                            </>
                                                        )
                                                        })
                                                    }  
                                            </ul>
                                        </Collapsible>
                                    </li> 
                                    )
                                })}

                                </>:
                                 <li> No categories found</li>
                                 }
                                </ul>
                            </div>                        
                        </div>
                    </div>
                    {/* <div className="menu-group">
                        <div className="menu-list">
                            <div style={{position: 'relative'}} onClick={() => openProduct()}>
                                <BsBorderAll className="menu-list-icon" /> 
                                <span>All Product</span>
                                <FiChevronDown className={isActivePro ? 'droperIcon yes-rotate': 'droperIcon'} />
                            </div>
                            <div className={isActivePro ? 'mobile-menu-inner is-open': 'mobile-menu-inner'}>
                                <ul>
                                {props.state && props.state.length > 0 ?
                                <>
                                {props.state && props.state.map((item,index)=>{
                                    return(
                                        <li key={index}>
                                        <h5  className="categoryClass"  onClick={() => openProduct2()}>{item.name}</h5>
                                        <div  className={isActivePro2 ? `mega-submenu mobile-menu-inner${index + 1} is-open`: `mobile-menu-inner`}>
                                            <ul>
                                                {item && item.sub_category.map((item1,index1)=>{
                                                    return(
                                                        <>
                                                            <li  key={index1} 
                                                            className={isActivePro2 ? `sub-head${index + 1} `: ``}
                                                             ><h5>{item1.name}</h5></li>
                                                            {item1 && item1.last_category.map((item2,index2)=>{
                                                            return(
                                                                <li key={index2}> 
                                                                <button style={{cursor : 'pointer '}} type="button" onClick={()=> productListfetch(item2.id)}> {item2.name}</button></li>
                                                                    )
                                                                })
                                                            }  
                                                            </>
                                                        )
                                                        })
                                                    }  
                                            </ul>

                                        </div>
                                    </li> 
                                    )
                                })}

                                </>:
                                 <li> No categories found</li>
                                 }
                                </ul>
                            </div>                        
                        </div>
                    </div> */}
                    <div className="menu-group">
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/your-account/profile">
                                <BiUserPin className="menu-list-icon" /> 
                                <span>My Profile</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/your-account/orders">
                                <BiArchive className="menu-list-icon" /> 
                                <span>My Orders</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/your-account/address">
                                <BsCursor className="menu-list-icon" /> 
                                <span>My Address</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/your-account/wishlist">
                                <BiBookmarkHeart className="menu-list-icon" /> 
                                <span>My Wislist</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/cart"><FiShoppingCart className="menu-list-icon" /> 
                                <span>My Cart</span>
                            </Link>                        
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/">
                                <MdOutlineLocalOffer className="menu-list-icon" /> 
                                <span>Today's Deals</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/">
                                <BiRupee className="menu-list-icon" /> 
                                <span>Aivi Pay</span>
                            </Link>
                        </div>
                        <div className="menu-list">
                            <a onClick={() => seOpenMenu(false)} href="https://seller.aivimart.com/">
                                <AiOutlineShop className="menu-list-icon" /> 
                                <span>  Sell on Aivimart </span>
                            </a>
                        </div>
                    </div>
                    <div className="menu-group">
                        <div className="menu-list">
                            <Link onClick={() => seOpenMenu(false)} to="/">
                                <BiMessageError className="menu-list-icon" /> 
                                <span>Help Desk</span>
                            </Link>                        
                        </div>
                        <div className="menu-list">
                            <a onClick={()=>logOutHandler()}>
                                <BiPowerOff className="menu-list-icon" /> 
                                <span> {Auth.auth && Auth.auth ? 'Logout'  : ' Login'} </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>        
        </div>        
        }
    </nav>
  );
}



export default Header;
