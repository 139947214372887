import React, {useEffect, useState , useContext} from "react";
import { Outlet, Link, useLocation  } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import Sidebar from "../sidebar/";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "../../../components/Slider/loader"
import { decrpt } from "../../../components/Slider/encryption";
import UserNameContext from "../../context/profile";

function Profile() {
  const location = useLocation();
  const [enableInfo,setEnableInfo] = useState(true);
  const [errors , setErrors ] = useState();
  const [openPassword,setOpenPassword] = useState(false);
  const [accountGender,setAccountGender] = useState();
  const [ userDetails , setUserDetails] = useState();
  const [loader , setLoader] = useState();
  const [profileName , setProfileName] = useState();
  const [username , setUsername ] = useState({
    name : userDetails && userDetails.name
  });
  
 
  let usernameProfile  = useContext(UserNameContext);
  
  console.log(usernameProfile, 'username check on user');
  const fetchUserDetails = async() => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      // console.log(token , 'token check');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/profile`)
      .then((response) => {
        setUserDetails(response.data.data);
        setAccountGender(response.data.data.gender);
        setProfileName(response.data.data.name);
        let nameUser = response.data.data.name;
        usernameProfile.setUserName(nameUser);
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'errors use details');
      setLoader(false);
    }
  }

  // console.log(profileName && profileName , 'cprofileName')

  const updateProfile = async() => {
    setLoader(true);
    const data = {
      name : profileName && profileName , 
      gender : accountGender,
      email : userDetails.email
    }
    console.log(data , 'check data')
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/profile/update` , data)
      .then((response) => {
        // console.log(response.data.data , 'update response')
        setUserDetails(response.data.data);
        setEnableInfo(false);
        setLoader(false)
        usernameProfile.setUserName(response.data.data.name);

      })
    }catch(error) {
      console.log(error , 'errrr');
      // setErrors(error.response.data.errors)
      setLoader(false)
    }
  }


// console.log(userDetails , 'updateUser');
// console.log(accountGender , 'accountGender on load');
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserDetails();
  }, [location]);

  // useEffect(() => {
  //   setUsername({
  //     ...username,
  //     name : userDetails && userDetails.name , 
  //   })
  // },[userDetails]);
  
  return (
    <div className="responsive-xs responsive-md-lg responsive-sm position-relative">
          {/* Produt List */}
          <section className="light-grey">
            <div className="container">
              <div className="account-section d-flex"> 
                <aside className="account-aside">
                  <Sidebar/> 
                </aside>
                <main className="account-main-area">
      {
        loader ? <div class="position-loader"><Loader /> </div>
        :
         <>
                  <div className="account-main-inner">
                    <div className="area-title">
                      <h4>My Profile</h4>
                      {enableInfo && enableInfo === true && 
                        <button className="area-top-btn" onClick={() => setEnableInfo(false)}><FiEdit2 /></button>
                      }
                    </div>
                    <div className="area-info" disabled={enableInfo}>
                      {/* Personal Information */}
                      <div>
                        <label>Personal Information</label>
                        <div className="d-flex">
                          <input type="text" className="text-dark" 
                            defaultValue={profileName && profileName} placeholder="First Name" 
                            onChange={e=> setProfileName(e.target.value)}
                           />
                          {/* <input type="text" className="" placeholder="Last Name" /> */}
                        </div>
                        {errors && errors.name ? <p style={{color : 'red' , fontSize : 12}}>{errors.name}</p> : ''}
                      </div>
    
                      {/* Gender */}
                      <div>
                        <label>Gender</label>
                        <div className="d-flex">
                          <div className="radio-btn" onClick={() => setAccountGender('M')}> 
                            <div className="radio-btn-circle"> 
                              {accountGender && accountGender == 'M' &&
                              <div className="radio-btn-check"></div>
                              }
                            </div>Male
                          </div>
                          <div className="radio-btn" onClick={() => setAccountGender('F')}> 
                            <div className="radio-btn-circle"> 
                              {accountGender && accountGender == 'F' &&
                              <div className="radio-btn-check"></div>
                              }
                            </div>Female
                          </div>
                        </div>
                      </div>
    
                      {/* Email */}
                      <div>
                        <label>Email Id & Phone Number</label>
                        <div className="d-flex">
                          <input type="text" className=""
                           value={userDetails && userDetails.email} 
                           placeholder="Your Email Id" 
                           onChange={e=> setUserDetails({...userDetails ,  email:e.target.value})}
                           />
                          <input  type="text" className="" value={userDetails && userDetails.mobile} placeholder="Your Phone Number" 
                            // onChange={e=> setUserDetails({...userDetails ,  mobile:e.target.value})} 
                            disabled
                          />
                        </div>
                        {errors && errors.email ? <p style={{color : 'red' , fontSize : 12}}>{errors.email}</p> : ''}

                      </div>
    
                      {/* Submit */}
                      <div className="d-flex" style={{marginTop:30}}>
                        <button className="account-submit-btn" onClick={() => updateProfile()}>
                          Save
                        </button>
                        <button className="account-cancel-btn" onClick={() => setEnableInfo(true)}>
                          Cancel
                        </button>
                      </div>
    
                    </div>
    
                    {/* Change Password */}
                    {/* <div className="area-title">
                      <h4>Change Password</h4>                    
                        {openPassword && openPassword ?
                        null
                        :<button className="area-top-btn" onClick={() => setOpenPassword(true)}><FiEdit2 /></button>}
                    </div> */}
                    {openPassword && openPassword && 
                    <div className="area-info">
                      {/* Personal Information */}
                      <div>
                        <label>New Password</label>
                        <div>
                          <input type="text" className="" placeholder="Your New Password" />
                          <input type="text" className="" placeholder="Retype New password " />
                        </div>
                      </div>
    
                      {/* Submit */}
                      <div className="d-flex" style={{marginTop:30}}>
                        <button className="account-submit-btn">
                          Save
                        </button>
                        <button className="account-cancel-btn" onClick={() => setOpenPassword(false)}>
                          Cancel
                        </button>
                      </div>
    
                    </div>
                    }
                  </div>
                  </>
               }
                </main>
              </div>
            </div>
          </section>
          <Outlet />
    </div>
  );
}

export default Profile;
