import React from "react";

export  const Nomatch = () => {



    return (
        <div className="m404">
            <p>404 </p>
            <p> Page Not Found</p>
        </div>
    );
}

