// // import React, { useEffect, useState } from 'react';
// // import axios from "axios";
// // import Loader from "../../components/Slider/loader";
// // // import { IoCallOutline} from "react-icons/io";

// // const ContactUs = () => {

// //   const [loader, setLoader] = useState();
// //   const [isProgress, setIsProgress] = useState(false);
// //   const [text, setText] = useState('');
// //   console.log(text && text.number, 'text text');

// //   const contactUs = async () => {
// //     setLoader(true)
// //     try {
// //       const response = await axios.get(`user/setting/helpline/view`);
// //       if (response.data.success) {
// //         setText(response.data.data);
// //       } else {
// //         alert('error cancellation Policy api call')
// //       }
// //       console.log(response);
// //       setLoader(false);
// //     } catch (error) {
// //       console.log(error);
// //       setLoader(false);
// //     }
// //   }

// //   useEffect(() => {
// //     contactUs();
// //   }, [])

// //   return (

// //     <div style={styles.bgBody}>
// //       {loader && loader ? <div className="position-loader"><Loader /></div> :
// //         <div style={styles.bgBodyInner}>
// //           <div style={{ backgroundColor: '#fff', height: '100%', }}>
// //             <div style={styles.bgCard}>
// //               <div style={styles.aboutTextSection}>
// //                 <div style={{ width: '60%' }}>
// //                   <p style={styles.pageNameText}>Aivimart Help Center | 24x7 Customer Care Support</p>
// //                   <p>At Jivi Mart, we value your feedback and inquiries. Whether you have a question about our products, need assistance with an order, or just want to get in touch, we're here to help.
// //                   </p>
// //                     <p>
// //                       Phone: For immediate assistance, you can reach us at 8474079428. Our customer support team is available from 9 AM to 6 PM, Monday through Saturday.
// //                     </p>

// //                     <p>
// //                     Email: If you prefer to contact us via email, feel free to write to us at jivimart21@gmail.com. We strive to respond to all emails within 24 hours.
// //                     </p>

// //                     <p>
// //                     Address: Visit us at our physical location: Khanapara, Guwahati, 781022. We're located in the heart of the city, making it convenient for you to drop by.
// //                     </p>
// //                     <p> We look forward to hearing from you and ensuring your experience with Jivi Mart is exceptional. Your satisfaction is our top priority!</p>
// //                 </div>
// //                 <div style={{ width: '40%', marginTop: 50 }}>
// //                   <img src={require('../../assets/contact.png')} />
// //                 </div>
// //                 {/* <p> {ReactHtmlParser(text)}</p> */}

// //               </div>
// //             </div>
// //           </div>

// //         </div>
// //       }
// //       {/* Divider */}
// //       <div style={styles.divider}></div>
// //     </div>
// //   );
// // };

// // export default ContactUs;
// // const styles = {
// //   divider: { padding: 40 },
// //   bgBody: { height: '100%' },
// //   bgBodyInner: { paddingLeft: 80, paddingRight: 80, textAlign: 'justify', paddingTop: 20 },
// //   pageNameText: {
// //     fontSize: 26, color: '#000000', fontWeight: 'bold',
// //     marginTop: 10, paddingTop: 20
// //   },
// //   bgCard: {
// //     marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5, borderColor: '#ddd', padding: 10,
// //   },
// //   aboutTextSection: { padding: 10, display: 'flex', columnGap: 80 },
// // }

// import React, { useEffect, useState } from 'react';
// import axios from "axios";
// import Loader from "../components/Slider/loader";

// const ContactUs = () => {

//   const [loader, setLoader] = useState();
//   const [isProgress, setIsProgress] = useState(false);
//   const [text, setText] = useState('');
//   console.log(text && text.number, 'text text');

//   const contactUs = async () => {
//     setLoader(true)
//     try {
//       const response = await axios.get(`user/setting/helpline/view`);
//       if (response.data.success) {
//         setText(response.data.data);
//       } else {
//         alert('error cancellation Policy api call')
//       }
//       console.log(response);
//       setLoader(false);
//     } catch (error) {
//       console.log(error);
//       setLoader(false);
//     }
//   }

//   useEffect(() => {
//     contactUs();
//   }, [])

//   return (

//     <div style={styles.bgBody}>
//       {loader && loader ? <div className="position-loader"><Loader /></div> :
//         <div style={styles.bgBodyInner}>
//           <div style={{ backgroundColor: '#fff', height: '100%', }}>
//             <div style={styles.bgCard}>
//               <div style={styles.aboutTextSection}>
//                 <div style={{ width: '60%' }}>
//                   <div>
//                   <p style={styles.pageNameText}>Aivimart Help Center | 24x7 Customer Care Support</p>
//                   </div>

//                   <div>

//                   <p style={styles.point}>At Jivi Mart, we value your feedback and inquiries. Whether you have a question about our products, need assistance with an order, or just want to get in touch, we're here to help.</p>
//                   </div>
//                   <p style={styles.point}>Phone: For immediate assistance, you can reach us at 8474079428. Our customer support team is available from 9 AM to 6 PM, Monday through Saturday.</p>
//                   <p style={styles.point}>Email: If you prefer to contact us via email, feel free to write to us at aivimart8@gmail.com. We strive to respond to all emails within 24 hours.</p>
//                   <p style={styles.point}>Address: Visit us at our physical location: Khanapara, Guwahati, 781022. We're located in the heart of the city, making it convenient for you to drop by.</p>
//                   <p style={styles.point}>We look forward to hearing from you and ensuring your experience with AiviMart is exceptional. Your satisfaction is our top priority!</p>
//                 </div>
//                 <div style={{ width: '40%', marginTop: 50 }}>
//                   <img src={require('../assets/contact.png')} alt="Contact Us"/>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       }
//       <div style={styles.divider}></div>
//     </div>
//   );
// };

// export default ContactUs;

// const styles = {
//   divider: { padding: 40 },
//   bgBody: { height: '100%' },
//   bgBodyInner: { paddingLeft: 80, paddingRight: 80, textAlign: 'justify', paddingTop: 20 },
//   pageNameText: {
//     fontSize: 26, color: '#000000', fontWeight: 'bold',
//     marginTop: 10, paddingTop: 20
//   },
//   bgCard: {
//     marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5, borderColor: '#ddd', padding: 10,
//   },
//   aboutTextSection: { padding: 10, display: 'flex', columnGap: 80 },
//   point: {
//     fontSize: 16, color: '#333333', marginBottom: 15,
//   },
// }

import React, { useEffect, useState } from 'react';
import axios from "axios";
import Loader from "../components/Slider/loader";

const ContactUs = () => {

  const [loader, setLoader] = useState();
  const [isProgress, setIsProgress] = useState(false);
  const [text, setText] = useState('');
  console.log(text && text.number, 'text text');

  const contactUs = async () => {
    setLoader(true)
    try {
      const response = await axios.get(`user/setting/helpline/view`);
      if (response.data.success) {
        setText(response.data.data);
      } else {
        alert('error cancellation Policy api call')
      }
      console.log(response);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    contactUs();
  }, [])

  return (

    <div style={styles.bgBody}>
      {loader && loader ? <div className="position-loader"><Loader /></div> :
        <div style={styles.bgBodyInner}>
          <div style={{ backgroundColor: '#fff', height: '100%', }}>
            <div style={styles.bgCard}>
              <div style={styles.aboutTextSection}>
                <div style={{ width: '60%' }}>
                  <div>
                    <p style={styles.pageNameText}>Contact Us</p>
                  </div>

                  <div>
                    <p style={styles.point}>You may contact us using the information below:</p>
                    <p style={styles.point}><strong>Merchant Legal Entity Name:</strong> HILLMART VENTURES PRIVATE LIMITED</p>
                    <p style={styles.point}><strong>Registered Address:</strong> SUDHALATA APARTMENT 1/4, OPP- VIVAVTA BY TAJ, KHANAPARA, Kamrup, ASSAM 781022</p>
                    <p style={styles.point}><strong>Operational Address:</strong> SUDHALATA APARTMENT 1/4, OPP- VIVAVTA BY TAJ, KHANAPARA, Kamrup, ASSAM 781022</p>
                    <p style={styles.point}><strong>Telephone No:</strong> 9394840857</p>
                    <p style={styles.point}><strong>E-Mail ID:</strong> aivimart8@gmail.com</p>
                  </div>
                </div>
                <div style={{ width: '40%', marginTop: 50 }}>
                  <img src={require('../assets/contact.png')} alt="Contact Us"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div style={styles.divider}></div>
    </div>
  );
};

export default ContactUs;

const styles = {
  divider: { padding: 40 },
  bgBody: { height: '100%' },
  bgBodyInner: { paddingLeft: 80, paddingRight: 80, textAlign: 'justify', paddingTop: 20 },
  pageNameText: {
    fontSize: 26, color: '#000000', fontWeight: 'bold',
    marginTop: 10, paddingTop: 20
  },
  bgCard: {
    marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5, borderColor: '#ddd', padding: 10,
  },
  aboutTextSection: { padding: 10, display: 'flex', columnGap: 80 },
  point: {
    fontSize: 16, color: '#333333', marginBottom: 15,
  },
}

