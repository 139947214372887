import React, {useEffect, useState} from "react";
import { Outlet, Link ,useParams } from "react-router-dom";

// import { AiOutlineCheckCircle } from "react-icons/ai";
// import { BsTrash} from "react-icons/bs";

function Confirm() {

    const params=useParams
  const {orderidcookie} =params()
  console.log({orderidcookie},'gggggggg')




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          {/* PageNav  */}
          <div className="order-pagination">
            <ul>
              <li>Cart</li>
              <li>Checkout</li>
              <li style={{color: '#0eb90e'}}>Confirmation</li>
            </ul>
          </div>
          
          {/* Cart */}
          <div className="section-main-confirm">
            <div className="text1">Thank You</div>
            <div className="text2">Your Order is placed </div>
            <div className="text3">Your Order no is {orderidcookie}. You will receive all text and a mail to your registered phone number and E-mail id. if you have any order related query, please send us an email or call us on +91 xxxxxxxxxx.</div>
            <div className="confirm-btn">
              <Link to="/">Go To Homepage</Link>
              <Link to="../your-account/orders">My Order</Link>
            </div>
          </div>
        </div>
      </section>
      <Outlet />
    </div>
  );
}

export default Confirm;




// import { useParams } from 'react-router-dom'
// import React from 'react'
// const Confirm = () => {
  
//   const params=useParams
//   const {orderidcookie} =params()
//   console.log({orderidcookie},'gggggggg')
//   return (
//     <div>ppppoooollllaaaabbbb</div>
//   )
// }

// export default Confirm
