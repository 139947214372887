import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";


function Account() {  
  useEffect (() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Routes>   
        <Route exact index path="/*" element={<AccountList />} />
        <Route exact path="/single/*" element={<ProductSingle />} />
        <Route exact path="/productSearch/*" element={<ProductSearch/>}/>
        <Route exact path="/productSearchDetails/" element={<ProductSearchDetails/>}/>
      </Routes> */}
      <Outlet />
    </div>

  );
}

export default Account;
