
import React, {useState,useEffect} from "react";
import { Link , useNavigate} from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../App.css';



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  }
};

function CategorySlider(props) {
  const navigate = useNavigate();
  let thirdCategorieSlider = props.thirdCategories;
  console.log(thirdCategorieSlider, ' CategorySlider props');
  const [deviceSize, setDeviceSize] = useState()
  const { innerWidth: width} = window;
  // console.log(props, 'props category');

  const checkDevice=()=>{
    if (width <= 425) {
      // alert('mobile');
      setDeviceSize("mobile");
    } else if (width <= 760) {
      // alert('tablet');
      setDeviceSize("tablet");
    } else {
      // alert('desktop');
      setDeviceSize("desktop");
    }
  }

  const productListfetch = (item ) => {
    navigate(`/product/productgrid/${item}`, { state: { item } })
 }

  useEffect(()=>{
    checkDevice();
  })
  
  return (
    <div className="">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        arrows={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="500ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
        deviceType={deviceSize}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {thirdCategorieSlider && thirdCategorieSlider.length > 0 ?
        thirdCategorieSlider.map((item,index) => {
          console.log("44774747474",item)
          return (
            <div className="category__thumbnail" key={index}> 
            <button className="category__image"  onClick={()=> productListfetch(item.id)}><img src={item && item.image} alt="" /></button>
              <button className="category__content" onClick={()=> productListfetch(item.id)}>
                <a className="category__name" >{item.name}</a>
                <div className="category__more">More</div>
              </button>
            </div>
          )
        })
        :
        <>
        </>
      }
      </Carousel>
      
    </div>
  );
}



export default CategorySlider;
