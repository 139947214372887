

import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import Loader from "../../components/Slider/loader";

const Disclaimer = () => {

    const [loader, setLoader] = useState();
    const [text, setText] = useState('');
    console.log(text, 'text text');

    const disclaimer = async () => {
        setLoader(true)
        try {
            const response = await axios.get(`user/setting/disc/view`);
            if (response.data.success) {
                setText(response.data.data.desc);
            } else {
                alert('error cancellation Policy api call')
            }
            console.log(response);
            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    }

    useEffect(() => {
        disclaimer();
    }, [])

    return (

        <div style={styles.bgBody}>
            {loader && loader ? <div className="position-loader"><Loader /></div> :
                <div style={styles.bgBodyInner}>
                    <div style={{ backgroundColor: '#fff', height: '100%', }}>
                        <p style={styles.pageNameText}>Refund and Return Policy for Aivi Mart</p>

                        <div style={styles.bgCard}>
                            <div style={styles.aboutTextSection}>
                                <p>Refunds:</p>
                                <p>
                                    If you are not completely satisfied with your purchase, you can request a refund within 7 days of receiving your order. To be eligible for a refund, the product must be unused, in its original packaging, and in the same condition as when you received it. Once we receive the returned item and inspect it, we will process your refund within 7-10 business days. The refund will be credited back to your original payment method.

                                </p>
                                <p>Returns:</p>
                                <p>
                                    If you wish to return a product, please contact our customer support team within 7 days of delivery. We will guide you through the return process. Please note that return shipping costs may apply unless the return is due to a defect or an error on our part.
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            }
            {/* Divider */}
            <div style={styles.divider}></div>
        </div>
    );
};

export default Disclaimer;
const styles = {
    divider: { padding: 40 },
    bgBody: { backgroundColor: '#f1f3f6', height: '100%' },
    bgBodyInner: { paddingLeft: 80, paddingRight: 80, textAlign: 'justify', paddingTop: 20 },
    pageNameText: {
        fontSize: 18, color: '#000000', fontWeight: 'bold',
        marginTop: 10, textAlign: 'center', paddingTop: 20
    },
    bgCard: {
        marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5, borderColor: '#ddd', padding: 10
    },
    aboutTextSection: { marginHorizontal: 10, padding: 10 },
}


