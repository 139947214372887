import React, {useEffect, useState} from "react";
import { Outlet, Link, useLocation , useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import Sidebar from "../sidebar/";
import Badge from 'react-bootstrap/Badge';
import axios from "axios";
import { decrpt } from "../../../components/Slider/encryption";
import Cookies from "js-cookie";
import Loader from "../../../components/Slider/loader";

function Orders() {
  const navigate = useNavigate();
  const [orderList , setOrderList ] = useState();
  console.log(orderList,'orderList orderList');
  const [loader , setLoader] = useState();



  const fetchOrderList = async() => {
    try {
      setLoader(true);
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/order/list`)
      .then((response) => {
        console.log(response.data , 'user details order');
        setOrderList(response.data.data);
        setLoader(false);
      })
    }catch(error) {
      // console.log(error , 'errors use details');
      setLoader(false);
    }
  }

  const orderSend =(item)=> {
    console.log(item, 'onlcick');
    navigate('/your-account/orders/detail' , {state : {item}});
  }

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrderList();
  }, [location]);
  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">

      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          <div className="account-section d-flex"> 
            <aside className="account-aside">
              <Sidebar /> 
            </aside>
            <main className="account-main-area">
            {loader && loader ? <div class="position-loader"><Loader /> </div>
              :
              <>
              <div className="account-main-inner">
                <div className="area-title">
                  <h4>My orders</h4>
                </div>
                <div className="scroll-div">
                      {orderList && orderList.length > 0 ?
                      <>
                      {orderList && orderList.map((item,index) => {
                        return (
                          <div className="area-order" key={index}>
                            <div className="order-image">
                              <img src={item.product_image} />
                              {item.total_items == '1' ? 
                                <div className="more-item">1 items</div>:
                                <div className="more-item"> + 1 items</div>
                              }
                            </div>
                            <div className="order-content">
                              <div className="d-flex" style={{justifyContent: 'space-between'}}>
                                <div className="orderId"><strong>Order Id :</strong> {item.order_uuid}</div>
                                <div className="orderId"><strong>Order Date :</strong> {item.order_date}</div>
                              </div>
                              <div><h5 class="cart-item-title">{item.product_title}</h5></div>
                              {/* <div className="mb-1">Order Status :  { item.order_status == 1 ? <Badge bg="primary"> New</Badge> : 
                                  (item.order_status == 2)  ? <Badge bg="success"> Accepted </Badge> : 
                                  (item.order_status == 6)  ? <Badge bg="danger"> Cancelled By Seller</Badge> :
                                  (item.order_status == 7)  ? <Badge bg="danger"> Cancelled </Badge> :  
                                  (item.order_status == 3) ? <Badge bg="warning"> Dispatch </Badge> : 
                                  (item.order_status == 4) ? <Badge bg="secondary"> Out For Delivered</Badge> : 
                                  <Badge bg="success"> Delivered</Badge> }</div>
                                {item.order_status == 5 &&
                                <div className="order-price">Delivered On : {item.delivered_date}</div>
                                } */}
                              <div className="order-price mb-1">Order Amount : <strong>₹{item.overall_price}</strong> </div>
                              <div className="order-price mb-1">Payment Type : <strong>{item.payment_type == "COD" ?  <Badge bg="success">  Cash On delivery </Badge> : <Badge bg="primary"> Online </Badge>}</strong> </div>
                              <div className="order-deatil">
                                <button
                                type="button" onClick={()=> orderSend(item.id)} 
                                className="order-deatil-btn">View Detail</button>
                                </div>
                            </div>
                          </div>
                        )}
                      )}
                      </>
                      : 
                      <div style={{textAlign: 'center'}}>
                        <img src={require('../../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                        <p>No data Found</p>
                      </div>
                      }
                </div>
              </div>
              </>
            }
            </main>
          </div>
        </div>
      </section>
      <Outlet />
    </div>
  );
}

export default Orders;
