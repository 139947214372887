import React, {useEffect, useState} from "react";
import { Outlet,  useLocation , useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import { decrpt } from "../../../components/Slider/encryption";
import Cookies from "js-cookie";
import Loader from "../../../components/Slider/loader";

function BankInformation() {
  const navigate = useNavigate();
  const [enableInfo,setEnableInfo] = useState(true);


  const location = useLocation();
                                                         

  useEffect(() => {
   

  }, [location]);



  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">

      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          <div className="account-section d-flex"> 
            <aside className="account-aside">
              <Sidebar /> 
            </aside>
            <main className="account-main-area">
            <div className="account-main-inner">
                <div className="area-title">
                  <h4>Bank Information</h4>
                  {enableInfo && enableInfo === true && 
                        <button className="area-top-btn" onClick={() => setEnableInfo(false)}><FiEdit2 /></button>
                      }
                </div>
                <div className="scroll-div">
                    <div className="bankpage" disabled={enableInfo}>
                        <div className="container">
                            <div className="row justify-content-center" disabled>
                                <div className="form-group col-md-6 col-11">
                                    <label>Account Holder Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group col-md-6 col-11">
                                    <label>Bank Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group col-md-6 col-11">
                                    <label>Branch Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group col-md-6 col-11">
                                    <label>Account Number</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group col-md-6 col-11">
                                    <label>IFSC Code</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group col-md-6 col-11">
                                    <label>MICR Code</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="apply-button col-md-2 col-12 ms-auto">
                                <div className="d-flex gap-2">
                                    <button onClick={() => setEnableInfo(true)} type="button" className="btn btn-secondary text-dark btn-sm" >Cancel</button>
                                    <button type="button" className="btn btn-primary text-dark btn-sm" style={{width : 'fitContent'}} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
          </div>
        </div>
      </section>
      <Outlet />
    </div>
  );
}

export default BankInformation;
