import React, {useEffect, useState} from "react";
import { Outlet, Link , useLocation , useNavigate} from "react-router-dom";
import { AiOutlineShopping,AiOutlineHeart } from "react-icons/ai";
import { BiFilterAlt, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import {FiChevronDown} from "react-icons/fi";
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from "axios";
import Loader from "../../components/Slider/loader";
import { Button } from "bootstrap";
import Pagination from "react-js-pagination";

function ProductsGridTradional() {
  const location = useLocation();
  const [value,setvalue] = useState(45)
  const [productList , setProductList] = useState();
  const [brandList , setBrandList] = useState();
  const [paginate , setPaginate] = useState();
  const [sizeList , setSizeList] = useState();
  const [loader , setLoader ] = useState(false);
  const [filterbtn , setFilterbtn ] = useState(false);

//   const item = location.state.item;
  
  const productListfetch = async( ) => {
    setLoader(true);
    try {
        const response = await axios.post(`/user/product/list`, {category_id : 67})
        .then(async (response) => {
          setProductList(response.data.data.list);
          setBrandList(response.data.brand_maps);
          setSizeList(response.data.size_maps);
          setPaginate(response.data.data.pagination)
          console.log(response , 'response');
        setLoader(false);
        })
    }catch(error) {
    }
  }


  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    try {
      const response = await axios.post(`/user/product/list?page=${pageNumber}`)
      if(response.data.success) {
        setProductList(response.data.data.list);
        setLoader(false);
      }else{
        setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    productListfetch();
  }, []);

  return (
    <>
    {
      loader && loader ? <div className="position-loader"><Loader  /></div> :
      <>
    <div className="responsive-xs responsive-md-lg responsive-sm">

        {/* Produt List */}
        <section className="light-grey">
          <div className="container">
            <div className="product-page">
 
              <div className="page-header">
                <div className="page-title">
                  <h4>Tablets</h4>
                  <h6>
                    <Link to="/">Home</Link> <BiChevronRight className="page-divide" />
                    <Link to="/product">Mobiles & Computers</Link> <BiChevronRight className="page-divide" />
                    <Link to="/product">All Mobiles Accessories</Link>
                  </h6>
                </div>
              </div>
              <div className="d-flex">              
                <aside className={`slidepanel ${filterbtn && filterbtn ? 'showfilter' : '' }`} >
                  {/* Price */}
                  <div className="slidepanel-block">
                    <h6>Price</h6>
                    <div className="slidepanel-inner">
                      <Range
                        // tipProps={true}
                        // allowCross={false}
                        // defaultValue={value}
                        // value={value}
                        // onChange={text => setvalue(value)}
                      />
                    </div>
                  </div>
  
                  {/* Brand */}
                  <div className="slidepanel-block">
                    <h6>Brand</h6>
                    <div className="slidepanel-inner">
                      {brandList && brandList.map((item,index)=>{
                        return(
                          <label className="slidepanel-label" key={index}>
                            <input type="checkbox" className="label-checkbox"/> {item.brand}
                          </label>
                        )
                      })}
                    </div>
                  </div>
                  {/* Brand */}
                  <div className="slidepanel-block">
                    <div>
                        <h6>Size</h6>
                      <div className="slidepanel-inner">
                        {sizeList && sizeList.map((item,index)=>{
                          return(
                            <label className="slidepanel-label" key={index}>
                              <input type="checkbox" className="label-checkbox"/> {item.size}
                            </label>
                          )
                        })}
                      </div>
                    </div>
                    <div>
                      <h6>Color</h6>
                      <div className="slidepanel-inner">
                        {sizeList && sizeList.map((item,index)=>{
                          return(
                            <label className="slidepanel-label" key={index}>
                              <input type="checkbox" className="label-checkbox"/> {item.size}
                            </label>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </aside>
                <div className="mainpanel">
                  {productList && productList.length > 0 ?
                  <div className="all-product">
                    {/* All product */}
                    {productList && productList.map((item,index) => {
                        return(
                          <div key={index} className="section__product">
                                <Link 
                                    to="/product/product-detail"
                                    state ={item}
                                    //  to={{
                                    //   pathname : "/product/product-detail",
                                    //   state : { item : item}
                                    //  }}
                                    > 
                                    <div className="product__image" >
                                      <img  src={item.main_image} alt="" />
                                      <div className="product__actions">
                                          {/* <div className="product__item"><button><AiOutlineShopping /></button></div> */}
                                          <div className="product__item"><button><AiOutlineHeart /></button></div>
                                      </div>
                                    </div>
                                </Link>

                                <div className="product__content">
                                    <Link 
                                        to="/product/product-detail"
                                        state ={item}> 
                                          <div className="product__title" >{item.name}</div>
                                          <div className="product__meta">
                                            <span className="product__price sale">₹ {item.price}</span><span className="product__del">₹ {item.mrp}</span>
                                          </div>
                                    </Link>
                                </div>
                          </div>
                        );
                      })
                    }
                     {/* Pagination */}
                    <div className="pagination">
                          {
                            productList && productList.length > 0 ?
                            <>
                              <div className="col-md-12">
                                  <Pagination
                                      totalItemsCount={ paginate && paginate.total_data}
                                      onChange={handlePageChange}
                                      activePage ={ paginate && paginate.current_page }
                                      currentPage={ paginate && paginate.current_page }
                                      itemsCountPerPage={paginate && paginate.per_page}
                                      pageRangeDisplayed={10}
                                  />
                              </div> 
                           </>
                              : <></>
                          
                          }
                    </div>
                  </div>
                  : 
                  <div style={{textAlign: 'center'}}>
                    <img src={require('../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                    <p>No data Found</p>
                  </div>
                  
                  }
                </div>  
              </div>

            </div>
          </div>
        </section>
      
        <Outlet />
    </div>
    </>
  }
  </>
  );
}

export default ProductsGridTradional;
