import React , {useState} from 'react';
import { useForm } from '@formspree/react';
import { useEffect } from 'react';
import axios from 'axios';
import pdf from '../../assets/jivimart-franchise.pdf'
import { Modal } from 'react-bootstrap';

function MyForm() {
    const [show , setShow] = useState(false);
    const [text , setText] = useState({
      name : '',
      number : '',
      email : '',
      location : ''
    });

    const orderStatusModalClose = () => {
      setShow(false);
    };

   const sendData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://formspree.io/f/mknanzdv`, text )
        setShow(true)
      } catch (error) {
        // console.log(error, 'check red')
      }
   }
   const run = () => {
    window.open = "/public/jivimart-franchise.pdf"
    // alert('sdhnjsnd')
   }

    return (
      <div className="container " >
        <div className='row justify-content-center'>
          <div className='col-md-6 col-12'>
            <div className='franchise-form'>
              <div className='header'>
                    <h3>Get the  Franchise Form</h3>
              </div>
                <div className='body'>
                  <form  onSubmit={sendData} >
                    <div className='row'>
                      <div className='form-group'>
                          <label>What's your name ?</label>
                          <input type="text" className='form-control' onChange={(e)=>setText({...text , name:(e.target.value)})} name="name"  required/>
                      </div>
                      <div className='form-group'>
                          <label>What's your Phone Number ?</label>
                          <input type="number" className='form-control' onChange={(e)=>setText({...text , number:(e.target.value)})} name="number" required/>
                      </div>
                      <div className='form-group'>
                          <label>What's your Email Address ?</label>
                          <input type="email" className='form-control' onChange={(e)=>setText({...text , email:(e.target.value)})} name="email" required/>
                      </div>
                      <div className='form-group'>
                          <label>From Where you want to do franchising ?</label>
                          <input type="text" className='form-control' onChange={(e)=>setText({...text , location:(e.target.value)})} name="location" required/>
                      </div>
                      <div className='button mt-4'>
                          <button type="submit"   className='btn btn-primary text-dark' >Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                <Modal size="md" show={show} onHide={orderStatusModalClose} centered>
                  <Modal.Header>
                      <h4> Your Form has been submitted</h4>
                  </Modal.Header>
                  <Modal.Footer className="p-1 justify-content-end" style={{borderTop:'none'}}>
                    <a className="btn text-dark btn-primary btn-sm" href={pdf}>To download  Franchise form Click Here  </a>
                  </Modal.Footer>
              </Modal>
                </div>
            </div>
          </div>
        </div>
      </div>
      )
    }

export default MyForm;