import React from 'react';

import { Spinner } from 'react-bootstrap';


function loader(props) {
    return (
        <div style={styles.loader}>
            <div className="loaderInner">
                <Spinner animation="border" size="xl"  style={{color:'#ff0000'}}/>
            </div>
            <h5 style={{marginTop:'10px'}}>Loading...</h5>
        </div>
    );
}

export default loader;

const styles = {
    loader:{color: '#8f8d8d', position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},
    
}