import React, {useEffect, useState,useContext} from "react";
import { Outlet, Link, useLocation , useNavigate } from "react-router-dom";
import { AiOutlineShopping } from "react-icons/ai";
import { BiTrash, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import Sidebar from "../sidebar/";
import axios from "axios";
import { decrpt } from "../../../components/Slider/encryption";
import Loader from "../../../components/Slider/loader";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import CountWishlistCartContext from '../../context/CountWishListCart';
import AuthUser from "../../context/AuthUser";

function Wishlist() {
  const Auth = useContext(AuthUser);
  const Count = useContext(CountWishlistCartContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [ loader , setLoader ] = useState();
  const [ data , setData] = useState();
  const [ wishlist , setWishlist ] = useState();
  console.log(wishlist,'wishlist');
  const [ showModal , setShowModal ] = useState(false);
  const [deleteLoader , setDeleteLoader ] = useState();
  const [deleteId, setDeleteId] = useState();


  
  const wishlistfetch = async() => {
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/wishlist/list`)
      .then((response) => {
        console.log(response.data , 'wishlist details');
        setWishlist(response.data.data);
        setData(response.data);
        setLoader(false);
        
      })
    }catch(error) {
      console.log(error , 'errors wishlist details');
      setLoader(false);
      
    }
  }

  const deleteModalClose = () => {
    setShow(false);
  };

  const wishListDeleteDilog = (id) => {
    setDeleteId(id);
    setDeleteLoader(false);
    setShow(true);
  }

  const appLoad = async() => {
    setLoader(true);
    if (Auth.auth && Auth.auth === true) {
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
            Count.setCountWishCart(response.data);
          setLoader(false);
          }
        })
      }catch(error){
        console.log(error , 'error 1');
        setLoader(false);
      }
    } else {
      try {
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
             setLoader(false);
          }
        })
      }catch(error){
        // console.log(error , 'error 2');
        setLoader(false);
      }
    }

  }

  const removeWishlist = async (id) => {
    setDeleteLoader(true);
    setShow(false);
    let data = {
      wishlist_id: id
    };

    try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/user/wishlist/delete`, data);
        console.log('delete response', response);
        if (response.data.success == true) {
          window.localStorage.removeItem("wishlistData")
           setWishlist(wishlist.filter((wishlist) => wishlist.id !== id));
           setDeleteLoader(false);
           setShow(false);
           appLoad();
          } else {
            alert(response.data.message);
          setShow(false);
          setDeleteLoader(false);
        }
    } catch (error) {
        console.log("Something Went Wrong Please Try Again", error);
        setShow(false);
    }
}

useEffect(() => {
    window.scrollTo(0, 0);
    wishlistfetch();
  }, [location]);

  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          <div className="account-section d-flex"> 
            <aside className="account-aside">
              <Sidebar /> 
            </aside>
                <main className="account-main-area">
            {
              loader && loader ? <div class="position-loader"><Loader /> </div> :
              <>
                  <h4 className="wishlist-title">My Wishlist</h4>
                  {wishlist && wishlist.length > 0 ?
                  <div className="all-product">
                    {/* All product */}
                    {wishlist && wishlist.map((item,index) => {
                      return (
                        <div key={index}   className="section__product">
                              <div className="product__image">
                            <Link 
                                  to="/product/product-detail"
                                  state ={item.product_id} 
                                 > 
                                <img src={item.image}  />
                            </Link>
                                <div className="product__actions">
                                    <div className="product__item"><button type="button" onClick={()=>wishListDeleteDilog(item.id)}><BiTrash /></button></div>
                                    {/* <div className="product__item"><button><AiOutlineShopping /></button></div> */}
                                </div>
                              </div>
                              <Link 
                                  to="/product/product-detail"
                                  state ={item.product_id} > 
                              <div className="product__content">
                                <div className="product__title">{item.product}</div>
                                <div> By {item.brand}</div>
                                <div className="product__meta">
                                  <span className="product__price sale">₹{item.price}</span>
                                  <span className="product__del">₹{item.mrp}</span>
                                </div>
                              </div>
                               </Link>
                        </div>
                      );
                    })}
                    
                    {/* Pagination */}
                    {/* <div className="pagination">
                      <ul>
                        <li><BiChevronLeft className="pagination-icon"/></li>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>...</li>
                        <li>15</li>
                        <li><BiChevronRight className="pagination-icon"/></li>
                      </ul>
                    </div> */}
               
                  </div>
                    : 
                    <div style={{textAlign: 'center'}}>
                      <img src={require('../../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                      <p>No data Found</p>
                    </div>
                    
                    }
              </>
            }
            </main>
          </div>
        </div>
      </section>
      <Modal size="sm" show={show} onHide={deleteModalClose} centered>
          <Modal.Header>
            <Modal.Title className="h6 fw-bolder">Are You Sure to Remove?</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="p-1 justify-content-end" style={{borderTop:'none'}}>
            <button onClick={()=>deleteModalClose()} className="btn btn-light btn-sm">
                No
            </button>
            <button onClick={()=>removeWishlist(deleteId)} className="btn ">
                Yes
            </button>
          </Modal.Footer>
        </Modal> 
      <Outlet />
    </div>
  );
}

export default Wishlist;
