import React, {useEffect, useState, useContext} from "react";
import { AiOutlineShopping,AiOutlineHeart,AiFillHeart } from "react-icons/ai";
import { Outlet, Link, useLocation, useNavigate  } from "react-router-dom";

const AddToCartBtn = (props) => {
    console.log(props.sizeType,'sizeType props');
    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/cart')
      }
      let cartData = props.newAddedData;
    //   console.log(cartData,'cartData');
      let exists = false;
      if (cartData && cartData) {
          exists = cartData.product_id === props.itemID;
        //   console.log(props.itemID,'exists');
      }
    return (
        <div>
            {!exists ? (
              <>
              {props.sizeType == 1 ?
                <button onClick={()=>props.cartScreenHandler(props.itemID)} className="item-btn-add-cart" >Add to cart</button>:
               <button onClick={()=>props.cartScreenHandler(props.itemID)} className="item-btn-add-cart" disabled={props.done} style={props.done ? {opacity:'.3'}:null}>Add to cart</button>
              }
              </>
            )
            :
            (
            <button onClick={() => goToLogin()} className="item-btn-add-cart">Go to cart</button>
            )}
        </div>
    );
};

export default AddToCartBtn;