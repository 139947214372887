import React, { Component } from 'react'
import { Link } from 'react-router-dom'

function Register() {
    return (
        <div className="login-page register">
            <div className='login-outer'>
                <div>
                    {/* <img src={require('../../assets/logo.png')} className="" alt="logo" /> */}
                </div>
                <div className='login-inner'>
                    <div><h2 >Create your Account</h2></div>
                    <div className='login-id'>
                        <label>Name </label>
                        <input type="text"  />
                    </div>
                    <div className='login-id'>
                        <label>Mobile </label>
                        <input type="text" maxlength="10" pattern="\d{10}" title="Please enter exactly 10 digits" />
                    </div>
                    <div className='form-group '>
                        <label>Gender </label>
                       <select className='gender-select'>
                            <option>Male</option>
                            <option>Female</option>
                       </select>
                    </div>
                    <button className='btn-primary' >Create Account</button>
                    <div className='mt-2'>
                    {/* <small>By continuing, you agree to jivimart's Conditions of Use and Privacy Notice.</small> */}
                    </div>
                </div>
                <div className='register-section'>
                    <div class="a-divider a-divider-break"><h5>Already have an account? </h5></div>
                </div>
                <div style={{width : '100%'}} className='btn-secondary'>
                    <span>
                        <Link to="/login"  >Login Here</Link>
                    </span>
                </div>
            </div>
        </div>
    );
} 

export default Register;