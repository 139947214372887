import React from 'react';

const AboutUs = () => {
    return (
        <div style={styles.bgBody}>
        <div style={styles.bgBodyInner}>
            <div style={{ height: '100%',}}>
              <div style={styles.bgCard}>
                <div  style={styles.aboutTextSection}>
                  <div style={{width:'60%'}}>
                     <p style={styles.pageNameText}>About us on Aivi Mart</p>
                     <p>Welcome to Aivi Mart! We are a one-stop shop for all your fashion needs. We provide
                      products such as apparel for kids and women, electronics items, and furniture. Our website
                      sells women's clothing ranging from traditional to modern. We strive to offer the lowest
                      possible prices, the best selection and variety, and the utmost convenience.</p>
                      <p>Our goal is to make shopping on the Web easier and hassle-free for everyone. We bring
                      you products from the best brands across the globe at competitive prices and high quality
                      standards. Moreover, our website is optimised for both desktop and mobile devices, making
                      it convenient for anyone to shop with us. We are committed to providing an excellent
                      shopping experience for all of our customers, from the first click to the doorbell.</p>
                      <p>We firmly believe that everyone should have access to the products and services they want at
                      fair prices. We take pride in our commitment to providing excellent customer service and the
                      highest quality products. We are here to help you find what you need, so please explore and
                      let us know how we can help you. We give utmost priority to delivering the best shopping
                      experience to our customers. We focus on making finding the products you are looking for
                      and navigating through the website hassle-free.</p>
                  </div>
                     <div style={{width:'40%',marginTop:50}}><img src={require('../../assets/eCommerce.png')} /></div>
                  {/* <p> {ReactHtmlParser(text)}</p> */}

                </div>
                <div>
                  <p>We understand that convenience is important to our customers, which is why we offer free
                  shipping on all orders over Rs. 1000. We also offer a 30-day return policy so that you can
                  shop with us worry-free. In addition, we have a team of friendly and knowledgeable
                  customer service representatives who are always available to help you with any questions
                  or concerns you may have.</p>

                  <p>We hope you enjoy your experience on our website. If you have any questions or
                  suggestions, please don't hesitate to reach out to us. Our customer support team will
                  provide the best customer experience.</p>

                  <p>Thank you for choosing our eCommerce website! We appreciate your uniqueness and look
                  forward to serving you in the future.</p>

                  <p style={{fontSize:18,fontWeight:'bold'}}>Why should you shop with us?</p>
                  <ul>
                    <li>We strive to offer the best possible prices.</li>
                    <li>Shopping online has become easier because we make it hassle-free for everyone.</li>
                    <li>You get high-quality products at a fair price.</li>
                    <li>We offer free shipping on all orders over Rs. 1,000.</li>
                    <li>30-day return policy</li>
                    <li>COD available</li>
                    <li>Knowledgeable customer service representatives that aim to give you the best customer experience</li>
                  </ul>
                </div>
            </div>
            </div>
          </div>
          <div style={{display: 'flex',columnGap: 80}}>
            {/* <div style={{width:'50%'}}><img src={require('../../assets/round.png')} /></div> */}
            <div style={{margin:'auto'}}><img src={require('../../assets/process.png')} /></div>
          </div>

          {/* Divider */}
          <div style={styles.divider}></div>
         </div>
    );
};

export default AboutUs;
const styles = {
  divider: { padding: 40 },
  bgBody:{height: '100%',backgroundColor:'#fbe399'},
  bgBodyInner: { paddingLeft:80,paddingRight:80,textAlign: 'justify',paddingTop:20},
  pageNameText:{fontSize:28, color: '#000000', fontWeight: 'bold',
  marginTop: 10,paddingTop:20 }, 
  bgCard: {
      marginHorizontal: 10, marginTop: 10, marginBottom: 10, borderRadius: 8, borderWidth: 0.5,borderColor: '#ddd',padding:10
  },
  aboutTextSection: {  padding:10,display:'flex',columnGap: 80},
}