import React, { useContext, useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
// import { AiOutlineCheckCircle } from "react-icons/ai";
// import { BsTrash} from "react-icons/bs";
import axios from "axios";
import { decrpt } from "../../components/Slider/encryption";
import Cookies from "js-cookie";
import Loader from "../../components/Slider/loader";
import UserNameContext from "../context/profile";
import useRazorpay from "react-razorpay";
// import { instamojo } from "pay-instamojo";





// let baseurl = "https://www.jivimart.com:3003/nodeapp"
// let baseurl = `${process.env.REACT_APP_.BASE_URL}`;
let baseurl = 'https://web-payment-service.onrender.com';
// port that worked on cpanel http://test.stri.co.in:3003/nodeapp;
// let baseurl = 'http://localhost:8080';


function Checkout() {
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedAddress, setSelectedAddress] = useState();
  const [openAddress, setOpenAddress] = useState(true);
  const [statusAddress, setStatusAddress] = useState(1);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  // console.log(paymentType,'paymentType');
  const [placeOrderState, setPlaceOrderState] = useState(true);
  const [loader, setLoader] = useState();
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [notStock, setNotStock] = useState('');
  const [addressData, setAddressData] = useState();
  const [editAddress, setEditAddress] = useState();
  const [newAddress, setNewAddress] = useState(false);
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [finalAddress, setFinalAddress] = useState();
  const [selectedPayment, setSelectedPayment] = useState(true);
  const [loaderMain, setLoaderMain] = useState();
  const [price, setPrice] = useState();
  const [orderId, setOrderId] = useState();
  const userName = useContext(UserNameContext);
  const [submiterror, setsubmiterror] = useState(false);
  // const [SubmitAddress, setSubmitAddress] = useState('');



  console.log(price && price, 'price userName')
  const orderIdValue = orderId && orderId;
  console.log(orderIdValue, 'orderIdValue');



  const checkDefaultAdddress = (item) => {
    setSelectedPayment(false)
    setSelectedAddress(item.id)
    console.log(item, 'check item for final')
    setFinalAddress({
      state_id: item.state,
      city_id: item.city,
      pin: item.pin,
      address: item.address,
      mobile: item.mobile,
      address_type: item.address_type,
      land_mark: item.land_mark,
      street: item.street,
      alt_mobile: item.alt_mobile
    })
  };

  // console.log(finalAddress , 'finalAddress check')
  const changestatusAddress = () => {
    console.log(statusAddress && statusAddress, 'statusAddress')
    if (statusAddress === 1) {
      setStatusAddress(2);
    } else if (statusAddress === 2) {
      setStatusAddress(1);
    } else (
      setStatusAddress(4)
    )
  };
  const handleEdit = (item) => {
    setStatusAddress(3);
    setEditAddress(item);
    console.log(editAddress && editAddress, 'check saved on state')
    console.log(item && item, 'check item')
  }


  const submitAddress = () => {
    setOpenAddress(false)
    setOpenPayment(true)
  };

  const changeAddress = () => {
    setOpenAddress(true)
    setOpenPayment(false)
  };

  const onSelectPayment = (type) => {
    setPlaceOrderState(false)
    if (type === 1) {
      setPaymentType(1);
    } else {
      setPaymentType(2);
    }
  }

  const handleState = (state) => {
    setNewAddress({
      ...newAddress,
      state: state,
    })
    setEditAddress({
      ...editAddress,
      state: state
    })
    console.log(newAddress, 'sdnksndksndks');
    cityFetch(state);
  }

  const stateFetch = async () => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/state/city/fetch`, { type: 1 })
      // console.log(response.data.data, 'state data');
      setState(response.data.data);
    } catch (err) {
      // console.log(err.response.data , 'err')
    }
  }

  const cityFetch = async (state) => {
    // console.log(newAddress, 'state check in city');
    try {
      const data = {
        state_id: state,
        type: 2
      }
      console.log(data, state, 'city fetch data')
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/state/city/fetch`, data)
        .then((response) => {
          setCity(response.data.data);
          console.log(response.data.data, 'city data');
        })
    } catch (err) {
      console.log(err.response.data, 'err')
    }
  }

  const pinCheck = async (pin) => {

    try {
      const data = {
        pin: newAddress.pin
      }
      const response = await axios.post(`/check/pin`, data)
        .then((response) => {
          console.log(response.data, 'pin check');
          setMessage(response.data.message);
          setError(false);
        })
    } catch (error) {
      console.log(error, 'pin chedck erro')
      setError(error.response.data.errors);
      setMessage(error.response.data.message);

    }
  }

  const pinCheckEdit = async (pin) => {
    try {
      const data = {
        pin: editAddress.pin
      }
      const response = await axios.post(`/check/pin`, data)
        .then((response) => {
          console.log(response.data, 'pin check');
          setMessage(response.data.message);
          setError(false);
        })
    } catch (error) {
      console.log(error, 'pin chedck erro')
      setError(error.response.data.errors);
      setMessage(false);
    }
  }

  const shippingList = async () => {
    setLoaderMain(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/shipping/address/list`)
        .then(response => {
          console.log(response.data.data, 'shipping list');
          setAddressData(response.data.data);
          setLoaderMain(false);
        })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteList = async (item) => {
    setLoader(true);
    // console.log(item , 'cskcnsk')
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/delete`, { id: item })
        .then(response => {
          console.log(response, 'response')
          setAddressData(addressData.filter((addressData) => addressData.id !== item));
          setLoader(false);
        })
    } catch (error) {
      console.log(error)
    }
  }

  const addAddres = async () => {
    try {
      setLoader(true);
      const data = {
        state_id: newAddress.state,
        city_id: newAddress.city,
        pin: newAddress.pin,
        address: newAddress.address,
        mobile: newAddress.mobile,
        address_type: newAddress.address_type,
        land_mark: newAddress.land_mark,
        street: newAddress.street,
        alt_mobile: newAddress.alt_mobile
      }
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/add/update`, data)
        .then(response => {
          console.log('response11111', response)
          setLoader(false);
          shippingList();
          setStatusAddress(1);
          setLoader(false);
        })
    } catch (error) {
      setLoader(false);
      console.log(error, 'error')
      setsubmiterror(error.response.data.errors)
    }
  }


  console.log(submiterror && submiterror, 'submiterror')
  const editAddressApi = async () => {
    try {
      setLoader(true);
      const data = {
        id: editAddress && editAddress.id,
        state_id: editAddress.state,
        city_id: editAddress.city,
        pin: editAddress.pin,
        address: editAddress.address,
        mobile: editAddress.mobile,
        address_type: editAddress.address_type,
        land_mark: editAddress.land_mark,
        street: editAddress.street,
        alt_mobile: editAddress.alt_mobile
      }
      console.log(data, 'check data edit')
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/shipping/address/add/update`, data)
        .then(response => {
          console.log(response.data, 'response')
          setLoader(false);
          shippingList();
        })

    } catch (error) {
      console.log(error, 'error')
      setError(error.response.data.errors)
      setLoader(false);
    }
  }

  const cartsummary = async () => {
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/cart/overallprice`)
        .then(response => {
          console.log(response.data, 'hzzxhzxh')
          setPrice(response.data);
        })
    } catch (error) {

    }
  }


  // instamojo patmeny


  // const placeOrder = async () => {
  //   setLoaderMain(true);
  //   try {
  //     const data = {
  //       payment_method: paymentType && paymentType,
  //       shipping_address_id: selectedAddress && selectedAddress
  //     }
  //     console.log(data, 'check data')
  //     let token = decrpt(Cookies.get('s_l'));
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //     const response = await axios.post(`/user/order/place`, data)
  //       .then(response => {
  //         console.log(response, 'rrreesssppoonne')
  //         setLoaderMain(false);
  //         // navigate('/confirm', { replace: true, state : response.data })
  //         console.log(response, 'response96969')
  //         Cookies.set("ordercookie", response.data.data.order_id,)

  //         if (data.payment_method && data.payment_method === 1) {

  //           runOrder(response, token);
           
  //           console.log(data.payment_method, 'onlinePay');

  //         } else {
  //           navigate('/Confirm', { replace: true, state: response.data })
  //         }
  //       })
  //   } catch (error) {
  //     console.log(error, 'error')
  //     if (error.response.data.success == false) {
  //       setNotStock(error.response.data.message)
  //       alert(error.response.data.message);
  //       setLoaderMain(false);
  //     }
  //   }
  // }


  /////POLLLABBBBB
  const placeOrder = async () => {
    setLoaderMain(true);
    try {
      const data = {
        payment_method: paymentType && paymentType,
        shipping_address_id: selectedAddress && selectedAddress
      }
      console.log(data, 'check data')
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/order/place`, data)
        .then(response => {
          console.log(response, 'rrreesssppoonne')
          setLoaderMain(false);
          const orderId = response.data.data.order_id;
          Cookies.set("ordercookie", orderId);
  
          if (data.payment_method && data.payment_method === 1) {
            runOrder(response, token);
            console.log(data.payment_method, 'onlinePay');
          } else {
            navigate(`/Confirm/${orderId}`, { replace: true, state: response.data })
          }
        })
    } catch (error) {
      console.log(error, 'error')
      if (error.response.data.success == false) {
        setNotStock(error.response.data.message)
        alert(error.response.data.message);
        setLoaderMain(false);
      }
    }
  }
  
  ////POLLABBBBB

  // 

  // const handlePayment = async(response_data) => {
  //   console.log(response_data , 'response_data')
  //   setLoader(true);
  //   try {
  //     let token = decrpt(Cookies.get('s_l'));
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //     const data = {
  //       // payment_method : paymentType && paymentType,
  //       // shipping_address_id : selectedAddress && selectedAddress
  //       purpose : 'Jivimart Payment Gateway 2',
  //       phone   : 8474003779,
  //       email   : 'suman@gmail.com',
  //       amount  : price?.total_cart_amount,
  //       buyer_name  :'mriganka hazarika',
  //       send_email  : false,
  //       send_sms    : false,  
  //       allow_repeated_payments : false,
  //       redirect_url   : `https://jvmart.backend.webinfoghy.co.in/public/api/user/order/make/payment/${response_data.data.data.id}`
  //     }
  //     console.log(data , 'check data on send')
  //     // axios.defaults.headers.common['X-Api-Key'] = `test_d883b3a8d2bc1adc7a535506713`;
  //     // axios.defaults.headers.common['X-Auth-Token'] = `test_dc229039d2232a260a2df3f7502`;
  //     axios.defaults.headers.common['X-Api-Key'] = `180c4491e7b7195c106a29e9b79fa14f`;
  //     axios.defaults.headers.common['X-Auth-Token'] = `30009eb95b26d7915d37386ca3060e28`;
  //     axios.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
  //     const response = await axios.post(`https://cors-anywhere.herokuapp.com/https://instamojo.com/api/1.1/payment-requests/` ,  data )
  //     // const response = await axios.post(`https://instamojo.com/api/1.1/payment-requests/` ,  data )
  //     .then(response => {
  //       setLoader(false);
  //       console.log(response,  ' check onlinePay');
  //       // navigate('/confirm', { replace: true, state : response.data })
  //       window.location.replace(`${response.data.payment_request.longurl}`);

  //       // if (response.status == 200 ) {
  //       //   // handlePayment(response);
  //       //   console.log(data.payment_method, 'onlinePay');
  //       //   window.location.replace(`${response.data.payment_request.longurl}`);
  //       // } else {
  //       //   // navigate('/confirm', { replace: true, state : response.data })
  //       //   navigate(`${response.data.payment_request.longurl}`)
  //       // }
  //     })
  //   }catch(error) {
  //     console.log(error , 'error')
  //   }
  // }
  // 



  //package
  // var Insta = require('instamojo-nodejs');
  // Insta.setKeys(API_KEY, AUTH_KEY);

  const paymentVerify = async (verifyData) => {
    try {

      console.log(verifyData, 'verifyData');
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post('/user/order/make/payment', verifyData);
      if (response.data.success === true) {
        navigate('/confirm', { replace: true, state: response.data })
      }
    } catch (error) {
      console.log(error);
    }
  };



  // async function runOrder(response, token) {
  //   console.log('token', response)
  //   try {
  //     const data = {
  //       "purpose": "Jivimart Payment Gateway",
  //       "amount": response?.data?.data?.amount,
  //       "buyer_name": response?.data?.data?.name,
  //       "email": response?.data?.data?.email,
  //       "phone": response?.data?.data?.mobile,
  //       "webhook": '/webhook/',
  //       "redirect_url": `${baseurl}/api/bid/callback`,
  //     }

  //     console.log(data, 'check daduah')
  //     let res = await fetch(`${baseurl}/api/bid`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data)
  //     }

  //     )
  //     if (res.ok === true) {
  //       console.log('ress check', res)
  //       let url = await res.json()
  //       console.log(url, 'check url')
  //       window.location.href = url.msg
  //       // paymentVerify();
  //     } else if (res.ok === "false") {
  //       console.log(" submision error")
  //     }
  //     else {
  //       console.log("error", res)
  //     }

  //   }
  //   catch (error) {
  //     console.log(error)

  //   }
  // }

  async function runOrder(response, token) {
    console.log('token', response)
    console.log('token11', token)
    try {
      const data =
      {
        "purpose": "Jivimart Payment Gateway",
        "amount": response?.data?.data?.amount,
        "buyer_name": response?.data?.data?.name,
        "email": response?.data?.data?.email,
        "phone": response?.data?.data?.mobile,
        "webhook": '/webhook/',
      
        // "redirect_url": `${baseurl}/api/bid/callback`,
        "redirect_url": `${baseurl}/callback/`,
      }



      console.log(data, 'check daduah')
      let res = await axios.post(`${baseurl}/api/bid/`, data)
        .then(res => {
          console.log('ress check', res)
          if (res.status === 200) {
            console.log('ress check', res)
            window.location.href = res.data.msg

          } else if (res.status === "false") {
            console.log(" submision error")
          }
          else {
            console.log("error", res)
          }
        })

    }
    catch (error) {
      console.log(error)

    }
  }




  useEffect(() => {
    window.scrollTo(0, 0);
    stateFetch();
    shippingList();
    cartsummary();
    // setSelectedAddress(true);
  }, [location]);






  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      {/* Produt List */}
      <section className="light-grey">
        <div className="container">
          {/* PageNav  */}
          <div className="order-pagination">
            <ul>
              <li>Cart</li>
              <li style={{ color: '#0eb90e' }}>Checkout</li>
              <li>Confirmation</li>
            </ul>
          </div>

          {/* Cart */}
          <div className="section-main-area d-flex">
            {
              loaderMain && loaderMain ? <div className="needed" style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <Loader style={{ top: 0 }} /> </div>
                :
                <>
                  <div className="checkoutSectionLeft">
                    {/* Address */}
                    {openAddress && openAddress ?
                      <div className="addressListBlock position-relative">
                        <div className="section-title active">Delivery Address</div>
                        {/*  */}
                        {loader ? <div className="position-loader2"> <Loader /> </div>
                          :
                          <>
                            <div className="adreesarea">
                              {statusAddress && statusAddress == 1 ?
                                <div>
                                  {addressData && addressData.length > 0 ?
                                    <>
                                      {addressData && addressData.map((item, index) => {
                                        return (
                                          <div className="addressListItems" key={item.id} onClick={checkDefaultAdddress.bind(this, item)}>
                                            <div className="addressRadio">
                                              {selectedAddress === item.id && (
                                                <span className="addressRadioChecked"></span>
                                              )}
                                            </div>
                                            {/* <div className="addressTitle"> {userName && userName.UserName} </div> */}
                                            <div className="addressDetail">  {item.address} <br />{item.land_mark + ', '} {item.street + ', '} {item.city}, {item.state} - {item.pin}</div>
                                            <div className="addressMobile"><span>Mobile: </span><strong>{item.mobile}</strong>
                                              {
                                                item && item.alt_mobile ?
                                                  <>
                                                    <span className="ms-2">Alternate  Mobile: </span><strong>{item.alt_mobile}</strong>
                                                  </>
                                                  : ''

                                              }
                                            </div>
                                            {selectedAddress === item.id && (
                                              <div className="addressButtons d-flex">
                                                <button type="button" onClick={() => deleteList(item.id)}>Remove</button>
                                                <button type="button" onClick={() => handleEdit(item)}>Edit</button>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      })}
                                    </> :
                                    <div class="address-blank">
                                      <img src={require('../../assets/notFound.jpg')} />
                                      <p>No Address found</p>
                                    </div>
                                  }
                                  <div className="addnew">
                                    {
                                      addressData && addressData.length < 3 ?
                                        <button type="button" onClick={changestatusAddress.bind()}>Add New Address</button>
                                        : ''
                                    }
                                    {selectedAddress && selectedAddress === null ?
                                      "lol" :
                                      <button type="button" class="placeOrderButton" disabled={selectedPayment} onClick={submitAddress.bind()}>Select Payment Option</button>
                                    }

                                  </div>
                                </div>
                                :
                                statusAddress && statusAddress == 2 ?
                                  <div>
                                    {/* check this form */}
                                    <div className="addressNewBlock d-flex">
                                      <div className="address-line">
                                        <label>State Names </label>
                                        <select className="form-control" onChange={state => handleState(state.target.value)}>
                                          <option selected disabled>----- select States from Below ----- </option>
                                          {state && state ? state.map((item, index) => {
                                            return (
                                              <option key={index} value={item.id}> {item.name}</option>
                                            );
                                          }) : <></>
                                          }
                                        </select>
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.state_id}</span> : ""}
                                      </div>

                                      <div className="address-line">
                                        <label>City Name </label>
                                        <select className="form-control" onChange={e => setNewAddress({ ...newAddress, city: e.target.value })}>
                                          <option selected disabled>----- select City From Below ----- </option>
                                          {city && city ? city.map((item, index) => {
                                            return (
                                              <option key={index} value={item.id}> {item.name}</option>
                                            );
                                          }) : <></>
                                          }
                                        </select>
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.city_id}</span> : ""}
                                      </div>

                                      <div className="address-line">
                                        <label>Pin Code </label>
                                        <input type="text" name="name" maxLength={8} className="form-control" placeholder=" Enter Pin Code"
                                          onKeyUp={() => pinCheck(newAddress.pin)}
                                          onChange={e => setNewAddress({ ...newAddress, pin: e.target.value })}
                                        />
                                        {message && message ? <p style={{ fontSize: '12px', color: 'green' }}>{message}</p> : ''}
                                        {error && error ? <p style={{ fontSize: '12px', color: 'red' }}>{error.pin}</p> : ''}
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.pin}</span> : ""}
                                      </div>

                                      <div className="address-line">
                                        <label>Mobile Phone</label>
                                        <input type="number" name="phone" className="form-control" placeholder=" Enter Mobile Number"
                                          onChange={e => setNewAddress({ ...newAddress, mobile: e.target.value })}
                                        // defaultValue={addressData && addressData.mobile}
                                        />
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.mobile}</span> : ""}
                                      </div>


                                      <div className="address-line">
                                        <label>Alternate Phone</label>
                                        <input type="number" name="phone" className="form-control" placeholder=" Enter Alternate Number"
                                          onChange={e => setNewAddress({ ...newAddress, alt_mobile: e.target.value })}
                                        />

                                      </div>

                                      <div className="address-line">
                                        <label>Landmark</label>
                                        <input type="text" name="phone" className="form-control" placeholder=" Enter Your Landmark"
                                          onChange={e => setNewAddress({ ...newAddress, land_mark: e.target.value })}
                                        />
                                      </div>

                                      <div className="address-line">
                                        <label>Street Name</label>
                                        <input type="text" name="phone" className="form-control" placeholder=" Enter Street Name"
                                          onChange={e => setNewAddress({ ...newAddress, street: e.target.value })}
                                        />
                                      </div>

                                      <div className="address-line">
                                        <label>Address Type</label>
                                        <select className="form-control" onChange={e => setNewAddress({ ...newAddress, address_type: e.target.value })}>
                                          <option selected disabled>------ Select Address Type From Below ------ </option>
                                          <option value="H">Home </option>
                                          <option value="O">Others</option>
                                        </select>
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.address_type}</span> : ""}
                                      </div>

                                      <div className="address-line textarea">
                                        <label>Address </label>
                                        <textarea name="address" className="form-control" placeholder="Enter House no, street, area"
                                          onChange={e => setNewAddress({ ...newAddress, address: e.target.value })}
                                        ></textarea>
                                        {submiterror && submiterror ? <span style={{ color: "red" }}>{submiterror?.address}</span> : ""}
                                      </div>

                                    </div>
                                    <div className="addAddressBtn">
                                      <button type="button" onClick={changestatusAddress.bind()}>Cancel</button>
                                      <button type="button" onClick={() => addAddres()}>Submit</button>
                                    </div>
                                    {/* check this form */}
                                  </div>

                                  //---------------------------------------------------------------------- if edit the this form ----------------------------------------------------------------------

                                  : statusAddress && statusAddress == 3 ?
                                    <div>
                                      <div className="addressNewBlock d-flex">
                                        <div className="address-line">
                                          <label>State Name </label>
                                          <select className="form-control" onChange={state => handleState(state.target.value)}>
                                            <option selected disabled>----- select States from Below ----- </option>
                                            {state && state ? state.map((item, index) => {
                                              return (
                                                <option key={index} value={item.id}> {item.name}</option>
                                              );
                                            }) : <></>
                                            }
                                          </select>
                                          {error && error.state_id ? <p style={{ color: 'red', fontSize: 12 }}>State Name is required</p> : ''}
                                        </div>
                                        <div className="address-line">
                                          <label>City Name </label>
                                          <select className="form-control" onChange={e => setEditAddress({ ...editAddress, city: e.target.value })}>
                                            <option selected disabled>----- select City From Below ----- </option>
                                            {city && city ? city.map((item, index) => {
                                              return (
                                                <option key={index} value={item.id}> {item.name}</option>
                                              );
                                            }) : <></>
                                            }
                                          </select>
                                          {error && error.city_id ? <p style={{ color: 'red', fontSize: 12 }}>City Name is required</p> : ''}
                                        </div>

                                        <div className="address-line">
                                          <label>Pin Code </label>
                                          <input type="text" name="name" maxLength={8} className="form-control" placeholder=" Enter Pin Code"
                                            onKeyUp={() => pinCheckEdit(editAddress.pin)}
                                            onChange={e => setEditAddress({ ...editAddress, pin: e.target.value })}
                                            defaultValue={editAddress && editAddress.pin}
                                          />
                                          {message && message ? <p style={{ fontSize: '12px', color: 'green' }}>{message}</p> : ''}
                                          {error && error ? <p style={{ fontSize: '12px', color: 'red' }}>{error.pin}</p> : ''}
                                        </div>

                                        <div className="address-line">
                                          <label>Mobile Phone</label>
                                          <input type="number" name="phone" className="form-control" placeholder=" Enter Mobile Number"
                                            onChange={e => setEditAddress({ ...editAddress, mobile: e.target.value })}
                                            defaultValue={editAddress && editAddress.mobile}
                                          />
                                        </div>
                                        <div className="address-line">
                                          <label>Alternate Phone</label>
                                          <input type="number" name="phone" className="form-control" placeholder=" Enter Alternate Number"
                                            onChange={e => setEditAddress({ ...editAddress, alt_mobile: e.target.value })}
                                            defaultValue={editAddress && editAddress.alt_mobile}
                                          />
                                        </div>
                                        <div className="address-line">
                                          <label>Landmark</label>
                                          <input type="text" name="phone" className="form-control" placeholder=" Enter Your Landmark"
                                            onChange={e => setEditAddress({ ...editAddress, land_mark: e.target.value })}
                                            defaultValue={editAddress && editAddress.land_mark}
                                          />
                                        </div>
                                        <div className="address-line">
                                          <label>Street Name</label>
                                          <input type="text" name="phone" className="form-control" placeholder=" Enter Street Name"
                                            onChange={e => setEditAddress({ ...editAddress, street: e.target.value })}
                                            defaultValue={editAddress && editAddress.street}
                                          />
                                        </div>
                                        <div className="address-line">
                                          <label>Address Type</label>
                                          <select className="form-control" defaultValue={editAddress && editAddress.address_type} onChange={e => setEditAddress({ ...editAddress, address_type: e.target.value })}>
                                            <option selected disabled>------ select from below ------ </option>
                                            <option value="H">Home </option>
                                            <option value="O">Others</option>
                                          </select>
                                        </div>
                                        <div className="address-line textarea">
                                          <label>Address </label>
                                          <textarea name="address" className="form-control" placeholder="Enter House no, street, area"
                                            onChange={e => setEditAddress({ ...editAddress, address: e.target.value })}
                                            defaultValue={editAddress && editAddress.address}
                                          ></textarea>
                                        </div>
                                      </div>
                                      {/* //---------------------------------------------------------------------- if edit the this form ---------------------------------------------------------------------- */}

                                      <div className="addAddressBtn">
                                        <button type="button" onClick={() => setStatusAddress(1)}>Cancel</button>
                                        <button type="button" onClick={() => editAddressApi()}>Submit</button>
                                      </div>
                                    </div> : ''
                              }
                            </div>
                            {/*  */}</>
                        }
                      </div>
                      :
                      <>
                        <div className="addressListBlock position-relative">
                          <div className="section-title active">Delivery Address</div>
                          <div className="p-3">
                            <div className="addressListItems"  >
                              <div className="addressRadio">
                                {selectedAddress === finalAddress.id !== null && (
                                  <span className="addressRadioChecked"></span>
                                )}
                              </div>
                              {/* <div className="addressTitle"> {userName && userName.UserName} </div> */}
                              <div className="addressDetail">  {finalAddress && finalAddress.address} <br />{finalAddress && finalAddress.land_mark + ', '} {finalAddress && finalAddress.street + ', '} {finalAddress && finalAddress.city}, {finalAddress && finalAddress.state} - {finalAddress && finalAddress.pin}</div>
                              <div className="addressMobile"><span>Mobile: </span><strong>{finalAddress && finalAddress.mobile}</strong>
                                <span className="ms-2">Alternate  Mobile: </span><strong>{finalAddress && finalAddress.alt_mobile}</strong>
                              </div>
                              {selectedAddress === finalAddress && finalAddress.id && (
                                <div className="addressButtons d-flex">
                                  <button type="button" onClick={() => deleteList(finalAddress && finalAddress.id)}>Remove</button>
                                  <button type="button">Edit</button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {/* Payment */}
                    <div className="addressListBlock">
                      {openPayment && openPayment ?
                        <div>
                          <div className="section-title active">Payment Option</div>
                          <div className="paymentarea">
                            <div className="addressListItems" onClick={() => onSelectPayment(2)}>
                              <button className="addressRadio" >
                                {paymentType && paymentType == 2 &&
                                  <span className="addressRadioChecked"></span>
                                }
                              </button>
                              <div className="payTitle">
                                Cash on delivery
                                <small>pay for your product at your doorstep</small>
                              </div>
                            </div>
                            <div className="addressListItems" onClick={() => onSelectPayment(1)}>
                              <button className="addressRadio">
                                {paymentType && paymentType == 1 &&
                                  <span className="addressRadioChecked"></span>
                                }
                              </button>
                              <div className="payTitle">
                                Pay on online
                                <small>pay for your product using credit / debit card, net banking.</small>
                              </div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                              <button className="placeOrderButton" onClick={() => placeOrder()} disabled={placeOrderState}>Place Order</button>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="section-title">Payment Option</div>
                      }
                    </div>
                  </div>
                  <div className="sectionRight">
                    <div className="section-title">Cart Summary</div>
                    <div className="section-item-summery">
                      <div className="section-summery-value">
                        <div>Cart Value</div>
                        <div>₹ {price && price.total_cart_amount}</div>
                      </div>
                      {/* <div className="section-summery-value">
                      <div>Discount</div>
                      <div>- ₹ 2,590</div>
                    </div> */}
                      <div className="section-summery-value">
                        <div>Delivery Charge</div>
                        <div>₹ {price && price.slab_price}</div>
                      </div>
                      <div className="section-summery-value">
                        <div>Grand Total</div>
                        <div>₹ {price && price.sub_total}</div>
                      </div>
                    </div>
                    <div className="section-summery-btn">
                      <Link to="/" className="section-outline-button">Continue Shopping</Link>
                    </div>
                  </div>
                </>
            }

          </div>
        </div>
      </section>
      <Outlet />
    </div>
  );
}

export default Checkout;
