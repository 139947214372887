import React, {useEffect, useState, useContext} from "react";
import { AiOutlineShopping,AiOutlineHeart,AiFillHeart } from "react-icons/ai";
import { Outlet, Link, useLocation, useNavigate  } from "react-router-dom";
import AuthUser from "../../screen/context/AuthUser";

const AddToWishListBtn = (props) => {
    const Auth = useContext(AuthUser);
    const navigate = useNavigate();
    const wishlistId = props.wishListData && props.wishListData.id;
    // console.log(wishlistId,'wishlistId wishlistId');
    const goToLogin = () => {
        navigate('/login')
      }
    return (
        <div>
            {Auth.auth != null ?
            <>
             {props.wishListData === null ? (
            <button onClick={() => props.wishListScreenHandler(props.itemID)} className="item-btn-wishlist"><AiOutlineHeart style={{fontSize:30}}/> </button>
            ):(
            <button onClick={()=>props.removeWishlist(wishlistId)} className="item-btn-wishlist"><AiFillHeart style={{fontSize:30}}/></button>
            )}
            </> :
            <button onClick={() => goToLogin()} className="item-btn-wishlist"><AiOutlineHeart style={{fontSize:30}}/> </button>
        }
        </div>
    );
};

export default AddToWishListBtn;