import React from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../App.css';

function Footer(props) {
    const [isShown, setIsShown] = React.useState(false);
    const navigate = useNavigate();
    console.log(props, 'footer props')
    return (
        <footer className="responsive-xs">
            {/* Footer Inner */}
            <section className="footerInner">
                <div className="container">
                    <div className='row'>
                        <div className="foot-side">
                            <div className="foot-nav-head">ABOUT</div>
                            {/* <a onClick={() => navigate('/additional/Shipping')} className="foot-nav-list">Shipping</a> */}

                            <a onClick={() => navigate('/contactUs')} className="foot-nav-list">Contact Us</a>
                            <a onClick={() => navigate('/additional/aboutUs')} className="foot-nav-list">About Us</a>
                            <Link style={{ color: 'white', textDecoration: 0, fontSize: 13, fontWeight: 500 }} to="/franchise">Franchise</Link>
                            <a href="https://seller.aivimart.com/" className="foot-nav-social">Become a seller</a>
                            {/* <a href="#" className="foot-nav-list">Corporate Information</a> */}
                        </div>
                        <div className="foot-side">
                            <div className="foot-nav-head">HELP</div>
                            {/* <a href="#" className="foot-nav-list">Payments</a> */}
                            <a onClick={() => navigate('/Shipping')} className="foot-nav-list">Shipping and Delivery </a>
                            <a onClick={() => navigate('/additional/RefundAndReturn')}className="foot-nav-list">Refund  &amp; Returns</a>
                            {/* <a href="#" className="foot-nav-list">FAQ</a>
                            <a href="#" className="foot-nav-list">Report Infringement</a> */}
                        </div>
                        <div className="foot-side">
                            <div className="foot-nav-head">POLICY</div>
                            {/* <a href="/additional/terms_condition" className="foot-nav-list">Terms & Condition</a> */}
                            <div
                                className="foot-nav-list"
                                onClick={() => navigate('/terms_condition')}
                            >
                                Terms & Condition
                            </div>

                            <div
                                className="foot-nav-list"
                                onClick={() => navigate('/cancellationPolicy')}
                            >
                                Cancellation and Refund Policy
                            </div>

                            <div
                                className="foot-nav-list"
                                onClick={() => navigate('/additional/disclaimer')}
                            >
                                Disclamer
                            </div>

                            <div
                                className="foot-nav-list"
                                onClick={() => navigate('/privacyPolicy')}
                                // onClick={() => navigate('/additional/privacyPolicy')}
                            >
                                Privacy policy
                            </div>
                            {/* <a href="/additional/privacyPolicy" className="foot-nav-list">Privacy Policy</a> */}
                            {/* <a href="/additional/disclaimer" className="foot-nav-list">Disclamer</a>
                            <a href="/additional/cancellationPolicy" className="foot-nav-list">Cancellation policy</a>
                         */}
                        </div>
                        <div className="foot-side">
                            <div className="foot-nav-head">SOCIAL</div>
                            <a href="https://www.facebook.com/" className="foot-nav-social">Facebook</a>
                            <a href="https://www.twitter.com/" className="foot-nav-social">Twitter</a>
                            <a href="https://www.youtube.com/" className="foot-nav-social">YouTube</a>
                        </div>
                        <div className="foot-side">
                            <div className="foot-nav-head">
                                <span>Mail Us :</span>
                            </div>
                            <div className="address-area">
                                {/* <p>Jivimart Private Limited, </p> */}
                                <p><a className="text-white text-decoration-none" href="mailto:hillmartinfratech051@gmail.com">hillmartventurespvtltd@gmail.com</a></p>
                                <p> <a className="text-white text-decoration-none" href="mailto:jivimart21@gmail.com">Aivimart8@gmail.com</a> </p>
                            </div>
                        </div>
                        <div className="foot-side">
                            <div className="foot-nav-head">
                                <span>Register Offices :</span>
                            </div>
                            <div className="address-area">
                                <p>hillmart Ventures Pvt ltd</p>
                                <p> Khanapara, Guwahati, 781022 </p>
                                <p> Assam, India</p>
                                <p>Call us at <a className="text-white text-decoration-none" href="tel:+916003236591">+91 8474079428</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright d-flex align-items-center">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-6">
                            <p>@ 2024 Hillmart Ventures Pvt Ltd. All Rights Reserved  </p>
                        </div>
                        <div className="col-md-4">
                            <img className="img-fluid" src={require('../../assets/payments.webp')} />
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}



export default Footer;
