import React, {useEffect, useState,useContext} from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BsTrash} from "react-icons/bs";
import axios from "axios";
import Loader from "../../components/Slider/loader";
import DeleteLoader from '../../components/deleteLoader';
import CartSummaryLoader from '../../components/cartSummaryLoader';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from "js-cookie";
import { decrpt } from "../../components/Slider/encryption";
import { Modal } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import CountWishlistCartContext from '../context/CountWishListCart';
import AuthUser from "../context/AuthUser";

function Cart() {
  const navigate = useNavigate();
  const Auth = useContext(AuthUser);
  const Count = useContext(CountWishlistCartContext);
  const [show,setShow] = useState(false);
  const [cartList,setcartList] = useState();

  const [overAllPrice,setOverAllPrice] = useState();
  console.log(cartList,'cartList');
  const [loader , setLoader ] = useState();
  const [loaderPrice , setLoaderPrice ] = useState();
  const [deleteLoader , setDeleteLoader ] = useState();
  const [deleteId, setDeleteId] = useState();

  // const [quantity,setQuantity] = useState(1);
  const [text, onChangeText] = useState("1");
 
  const cartListFetch=async()=>{
    setLoader(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      console.log(token,'tokentoken');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`/user/cart/list`);
      // console.log(response.data,'cart data');
      setcartList(response.data.data);
      setLoader(false);
    } catch (error) {
      console.log(error,'error');
      setLoader(false);
    }
  }

  const overAllPriceFetch = async() =>{
    setLoaderPrice(true);
    try {
      let token = decrpt(Cookies.get('s_l'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`user/cart/overallprice`);
      if(response.data.success==true){
        setOverAllPrice(response.data)
        setLoaderPrice(false);
    }
    } catch (error) {
      console.log(error,'error');
      setLoaderPrice(false);
    }
  }




  const deleteModalClose = () => {
    setShow(false);
  };

  const cartDeleteDilog = (id) => {
    setDeleteId(id);
    setDeleteLoader(false);
    setShow(true);
  }

  const appLoad = async() => {
    setLoader(true);
    if (Auth.auth && Auth.auth === true) {
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
            Count.setCountWishCart(response.data);
          setLoader(false);
          }
        })
      }catch(error){
        console.log(error , 'error 1');
        setLoader(false);
      }
    } else {
      try {
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
             setLoader(false);
          }
        })
      }catch(error){
        // console.log(error , 'error 2');
        setLoader(false);
      }
    }

  }

  const removeProduct = async (id) => {
    setDeleteLoader(true);
    setShow(false);
    let data = {
      cart_id: id
    };

    try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/user/cart/remove`, data);
        console.log('delete response', response);
        if (response.data.success == true) {
          setcartList(cartList.filter((cartList) => cartList.id !== id));
           setDeleteLoader(false);
          setShow(false);
          overAllPriceFetch();
          appLoad();
          } else {
            alert(response.data.message);
          setShow(false);
          setDeleteLoader(false);
        }
    } catch (error) {
        console.log("Something Went Wrong Please Try Again", error);
        setShow(false);
    }
}

  const submitCart = () => {
    navigate('/checkout/', { replace: true })
  };

  const location = useLocation();

  const cartUpdate = async (item, type) => {
    if (item && item.quantity == 1 && type == 1 ){
    }else{
        console.log('Quantity can be',item && item.quantity)
        let data = {
            cart_id: item && item.id,
            quantity: type == 1 ? (parseInt(item && item.quantity) - 1) : (parseInt(item && item.quantity) + 1),
            product_id:item && item.product_id,
            product_size_id:item && item.product_size_id,
        }
        console.log("Data", data);
        try {
            let token = decrpt(Cookies.get('s_l'));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(`/user/cart/add`, data);
            console.log('add cart data', response.data);
            if (response.data.success == true) {
              let newData = response.data.data;
              let tmparray = cartList;
              let obj = tmparray.find((o, i) => {
                if (o.id === newData.id) {
                      tmparray[i] = {...cartList[i] ,   ...newData  };
                      return true; 
                  }
              });
                overAllPriceFetch();
              }  else {
               
            }
        } catch (error) {
            console.log(error);
            toast.warn(error.response.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            // alert(error.response.data.message)
        }
    }
}

  useEffect(() => {
    window.scrollTo(0, 0);
    cartListFetch();
    overAllPriceFetch();
  }, []);
  return (
    <div className="responsive-xs responsive-md-lg responsive-sm">
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'colored'}/> 
        {loader && loader ? <div className="position-loader"><Loader  /></div>  : 
        <>
      {/* Produt List */}
        <section className="light-grey">
          <div className="container">
            {/* PageNav  */}
            <div className="order-pagination">
              <ul>
                <li style={{color: '#0eb90e'}}>Cart</li>
                <li>Checkout</li>
                <li>Confirmation</li>
              </ul>
            </div>
            
            {/* Cart */}
            {deleteLoader && deleteLoader ? <div className="position-loader"><DeleteLoader  /></div>  : 
            <>
            {cartList && cartList.length ?
                      <>
            <div className="section-main-area d-flex">
              <div className="sectionLeft">
                <div className="section-title">Shopping Cart</div>              
                <div className="cart-item-block">
                  {/* item 1 */}
                  
                      {cartList && cartList.map((item,index)=>{
                        return(
                          <div className="cart-item d-flex" key={index}>
                            <div className="cart-item-image">
                              <img src={item.image} alt=""/>
                            </div>
                            <div className="cart-item-detail">
                              <h5 className="cart-item-title">{item.product_name}</h5>
                                <h5 className="cart-item-sold">{item.brand}</h5>
                                <div className="cart-item-price">
                                  <div className="d-flex">
                                    <span className="cart-item-amount">₹ {item.price}</span>
                                    <span className="cart-item-mrp ">₹ {item.mrp}</span>
                                    <span className="cart-item-discount">({(parseInt((item && item.mrp)-(item && item.price))/(item && item.mrp) * parseInt(100)).toFixed(2)}%)</span>
                                  </div>
                                </div>
                                <div className="cart-item-quantity">
                                  <h6>Quantity</h6>
                                  <div className="cart-item-quantity-inner">
                                    <span>
                                      <button onClick={() => cartUpdate(item,1)}><AiOutlineMinus /></button>
                                    </span>
                                        <span><input type="text" maxLength="3"  onChange={onChangeText} value={item && item.quantity} style={{width: 50,textAlign: 'center'}}/></span>
                                      <span>
                                        <button onClick={() => cartUpdate(item,2)}><AiOutlinePlus /></button>
                                      </span>
                                  </div>
                                </div>
                              </div>
                              <button className="cart-item-remove" onClick={()=>cartDeleteDilog(item.id)}><BsTrash /></button>
                            </div>
                        )
                      })
                      }

                  </div>
              </div>
              
                <div className="sectionRight" style={{position:'relative'}}>
                  <div className="section-title">Cart Summary</div>
                  {loaderPrice && loaderPrice ? <div className="position-loader-cartSummary"><CartSummaryLoader  /></div>  : 
                    <>
                  <div className="section-item-summery">
                    <div className="section-summery-value">
                      <div>Cart Value</div>
                      <div>₹ {overAllPrice && overAllPrice.total_cart_amount}</div>
                    </div>
                    <div className="section-summery-value">
                      <div>Delivery Charge</div>
                      <div>₹ {overAllPrice && overAllPrice.slab_price}</div>
                    </div>
                    <div className="section-summery-value">
                      <div>Grand Total</div>
                      <div>₹ {overAllPrice && overAllPrice.sub_total}</div>
                    </div>
                  </div>
                  <div className="section-summery-btn">
                    <Link to="/" className="section-outline-button" onClick={submitCart}>Continue Shopping</Link>
                    <button className="section-button" onClick={submitCart}>Proceed To Checkout</button>
                  </div>
                       </>
                      }
                </div>
         
            </div>
            </>:
              <div style={{textAlign: 'center',marginTop:20 , height : 400 , flex : 'row' , flexDirection : 'column' , alignItems : 'center' , justifyContent : 'center' , display : 'flex'}}>
                <img src={require('../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                <p>No data Found</p>
            </div>
              }
            </>
            }
          </div>
        </section>
        <Modal size="sm" show={show} onHide={deleteModalClose} centered>
            <Modal.Header>
              <Modal.Title className="h6 fw-bolder">Are You Sure to Remove?</Modal.Title>
            </Modal.Header>
            <Modal.Footer className="p-1 justify-content-end" style={{borderTop:'none'}}>
              <button onClick={()=>deleteModalClose()} className="btn btn-light btn-sm">
                  No
              </button>
              <button onClick={()=>removeProduct(deleteId)} className="btn ">
                  Yes
              </button>
            </Modal.Footer>
        </Modal>
      <Outlet />
      </>
      }
    </div>
  );
}

export default Cart;
