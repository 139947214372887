import React, {useEffect, useState} from "react";
import { Outlet, Link ,useLocation} from "react-router-dom";
import { AiOutlineShopping,AiOutlineHeart } from "react-icons/ai";
import { BiFilterAlt, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import Loader from "../../components/Slider/loader";
import {Helmet} from "react-helmet";



const ProductSearch = () => {
  const location = useLocation();
  const searchData = location.state.searchDataArray;
  const loader=location.state.loader
  console.log(searchData,'loader useLocation');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loader]);
    return (
        <div className="responsive-xs responsive-md-lg responsive-sm" >
          {loader && loader ? <div className="position-loader"><Loader  /></div>  : 
            <>
        {/* Produt List */}
        <section className="light-grey">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link rel="canonical" href="data check" />
            </Helmet>
          <div className="container">
            <div className="product-page">
              <div> 
                 <div className="mainpanelProductSearch">
                  
                  {searchData && searchData.length > 0 ?
                  <div className="all-product">
                    {/* All product */}
                    {searchData && searchData.map((item,index) => {
                        return(
                          <div key={index} className="section__product">
                                <Link 
                                    // to="/product/product-detail"
                                    to={`/product/product-detail/${item.id}-${(item.name).replace(/\s+/g, '-')}` }
                                    state ={item}
                                    > 
                                </Link>
                                <div className="product__image" >
                                  <img  src={item.main_image} alt="" />
                                  <div className="product__actions">
                                      {/* <div className="product__item"><button><AiOutlineShopping /></button></div> */}
                                      {/* <div className="product__item"><button><AiOutlineHeart /></button></div> */}
                                  </div>
                                </div>

                                <div className="product__content">
                                    <Link 
                                        // to="/product/product-detail"
                                        to={`/product/product-detail/${item.id}-${(item.name).replace(/\s+/g, '-')}` }
                                        state ={item}> 
                                          <div className="product__title" >{item.name} ({item.third_category})</div>
                                          <div className="product__meta">
                                            <span className="product__price sale">₹ {item.price}</span><span className="product__del">₹ {item.mrp}</span>
                                          </div>
                                    </Link>
                                </div>
                          </div>
                        );
                      })
                    }

                    <div className="pagination mt-5">
                  </div>
                  </div>
                  : 
                  <div style={{textAlign: 'center'}}>
                    <img src={require('../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                    <p>No data Found</p>
                  </div>
                  
                  }
                  
                </div>  
              </div>
            </div>
          </div>
        </section>
        <Outlet />
        </>
      }
      </div>
    );
};

export default ProductSearch;