import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";
import { AiOutlineShopping, AiOutlineHeart } from "react-icons/ai";
import { BiFilterAlt, BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from "axios";
import Loader from "../../components/Slider/loader";
import { Button } from "bootstrap";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";



function ProductsGrid() {
  // let { getID } = useParams();
  // console.log(getID && getID , 'getID');
  const location = useLocation();
  const [valueCheck, setvalueCheck] = useState();
  const [bradecrum, setBradecrum] = useState();
  const [loaderProduct, setLoaderProduct] = useState();
  const [rangeValue, setRangeValue] = useState();
  const [productList, setProductList] = useState();
  const [brandList, setBrandList] = useState();
  const [paginate, setPaginate] = useState();
  const [sizeList, setSizeList] = useState();
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState();
  const [filterbtn, setFilterbtn] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [isActivePro, setActivePro] = useState(false);
  const [arraypo, setarraypo] = useState([]);
  const [maxPrice, setMaxPrice] = useState();
  const [screenDimensions, setScreenDimensions] = React.useState({
    width: window.screen.width,
    height: window.screen.height
  });

  // console.log(bradecrum && bradecrum , 'bradecrum value')
  // console.log(newmax , 'checknewmax value')

  const getScreenDimensions = (e) => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  const item = location.state.item;
  // console.log(maxPrice && maxPrice ,' maxPrice chekc')
  const productListfetch = async () => {
    setLoader(true);
    try {
      const response = await axios.post(`/user/product/list`, { category_id: item })
        .then((response) => {
          if (response.data.data.list.length > 0) {
            setBradecrum(response.data.data.list[0])
          }
          console.log('shdiusahdiushiu', response)
          setProductList(response.data.data.list);
          setBrandList(response.data.brand_maps);
          setSizeList(response.data.size_maps);
          setFilterBy(response.data.filter_maps);
          setPaginate(response.data.data.pagination)
          setLoader(false);
          let newmax = []
          if (response.data.data.list) {
            response.data.data.list.map((item) => {
              // console.log(item , 'cmskcmk')
              newmax.push(item.price);
            })
          }
          setMaxPrice(Math.max(...newmax))
          setvalueCheck(newmax);
          setRangeValue(newmax)
          // console.log(newmax, 'check res list product')
        })
    } catch (error) {
    }
  }


  const handlePageChange = async (pageNumber) => {
    setLoader(true);
    try {
      // let token = decrpt(Cookies.get('s_l'));
      // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/product/list?page=${pageNumber}`, { category_id: item })
      if (response.data.success) {
        setProductList(response.data.data.list);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  }

  const brandfetch = async (item) => {
    console.log(item, 'item.brand_id')
    setLoaderProduct(true);
    arraypo.push(item)
    console.log(arraypo && arraypo, 'item array push')

    // console.log(arraypo , 'check array')
    try {
      const data = {

        brand_id: arraypo && arraypo,

        category_id: location.state && location.state.item
      }
      console.log(data, 'check for brand fetch')
      await axios.post(`/user/product/list`, data)
        .then(res => {
          setProductList(res.data.data.list);
          setLoaderProduct(false);
          console.log(res.data.data, 'check for brand res')

        })
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const sizeFetch = async (item) => {
    try {
      setLoaderProduct(true);
      const data = { size_id: item, category_id: location.state && location.state.item }
      console.log(data, 'check for size fetch')
      await axios.post(`/user/product/list`, data)
        .then(res => {
          setProductList(res.data.data.list);
          setLoaderProduct(false);
        })
    } catch (error) {
      console.log(error, 'error')
    }
  }
  const filterByFetch = async (item) => {
    try {
      setLoaderProduct(true);
      const data = { filter_master_id: item, category_id: location.state && location.state.item }
      console.log(data, 'check for size fetch')
      await axios.post(`/user/product/list`, data)
        .then(res => {
          setProductList(res.data.data.list);
          setLoaderProduct(false);
        })
    } catch (error) {
      console.log(error, 'error')
    }
  }


  const openProduct = () => {
    if (isActivePro == true) {
      setActivePro(false);
    } else {
      setActivePro(true);
    }
  }

  const handlePrice = async (value) => {
    setLoaderProduct(true);
    try {
      console.log(value, 'ckjs')
      const data = {
        category_id: item,
        from_amount: value[0],
        to_amount: value[1]
      }
      const response = await axios.post(`/user/product/list `, data)
        .then(res => {
          console.log(res, 'check apicscsc')
          setProductList(res.data.data.list);
          setLoaderProduct(false);
          setRangeValue(data ? data : valueCheck && valueCheck);
        })
    } catch (error) {
      console.log(error, 'check error')
      setLoaderProduct(false);
    }
  }

  // console.log(rangeValue, 'rangeValue')


  useEffect(() => {
    window.scrollTo(0, 0);
    productListfetch(item);
    window.addEventListener("resize", getScreenDimensions);
    if (screenDimensions.width < 400) {
      setActivePro(true);
    } else {
      setActivePro(false);
    }
  }, [item]);

  return (
    <>
      {
        loader && loader ? <div className="position-loader"><Loader /></div> :
          <>
            <div className="responsive-xs responsive-md-lg responsive-sm">
              <Helmet>
                <meta charSet="utf-8" />
                <title>{bradecrum && bradecrum.third_category}</title>
              </Helmet>
              {/* Produt List */}
              <section className="light-grey">
                <div className="container">
                  <div className="product-page">

                    <div className="page-header">
                      <div className="page-title">
                        <h4>{bradecrum && bradecrum.third_category}</h4>
                        <h6 className="align-items-center">
                          <a style={{ display: 'flex', alignItems: 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">Home <BiChevronRight className="page-divide" /></a>
                          <a style={{ display: 'flex', alignItems: 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">{bradecrum && bradecrum.sub_category}<BiChevronRight className="page-divide" /> </a>
                          <a style={{ display: 'flex', alignItems: 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">{bradecrum && bradecrum.third_category}</a>
                        </h6>
                        <div className="fliter-btn-div">
                          <button type="button" onClick={() => openProduct()} className="fliter-btn"><BiFilterAlt className="page-filter-icon " />Fliter</button>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <aside className={`slidepanel  ${isActivePro ? 'filter-pro ' : 'filter-pro.is-open-pro '}`} >
                        {/* Category */}
                        {/* <div className="slidepanel-block">
                    <h5>Category</h5>
                    <div className="slidepanel-inner">
                      {
                        productList &&  productList.map((item ,index) => {
                          return (
                            <label className="slidepanel-label" key={index}>
                            <Link to="/product/productgrid"><BiChevronRight className="label-icon"/> {item.category}</Link>
                          </label>      
                          );
                        })
                      }
                    </div>
                  </div> */}

                        {/* Price */}
                        <div className="slidepanel-block">
                          <h6>Price</h6>
                          <div className="slidepanel-inner">
                            <Range
                              min={1}
                              max={maxPrice + 200}
                              step={20}
                              //  value={valueCheck && valueCheck}
                              onAfterChange={(value) => handlePrice(value)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <p className="rangenumber">{rangeValue && rangeValue.from_amount ? rangeValue.from_amount : 1}</p>
                              <p className="rangenumber">{rangeValue && rangeValue.to_amount ? rangeValue.to_amount : maxPrice}</p>
                            </div>
                          </div>
                        </div>

                        {/* Sub Category */}
                        {/* <div className="slidepanel-block">
                    <h5>Sub Category</h5>
                    <div className="slidepanel-inner">
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Tablets
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Batteries
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Case And Covers
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Screen Protectors
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Power Banks
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> All Certified Refurbished
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Wearable Devices
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Smart Home
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Office Supplies & Amp: Stationery
                      </label>
                      <label className="slidepanel-label">
                          <input type="checkbox" className="label-checkbox"/> Software
                      </label>
                    </div>
                  </div> */}

                        {/* Brand */}
                        <>
                          {
                            brandList && brandList.length >= 1 ?
                              <>
                                <div className="slidepanel-block">
                                  <h6>Brand</h6>
                                  <div className="slidepanel-inner">
                                    {brandList && brandList.map((item, index) => {
                                      return (
                                        <label className="slidepanel-label" key={index}>
                                          <input checked={item.brand_id !== null ? checked : ''} onClick={() => brandfetch(item.brand_id)} value={item.brand_id} type="checkbox" className="label-checkbox" /> {item.brand}
                                        </label>
                                      )
                                    })}
                                  </div>
                                </div>
                              </>
                              : ""
                          }
                        </>
                        {/* Brand */}
                        {
                          sizeList && sizeList.length >= 1 ?
                            <div className="slidepanel-block">
                              <div>
                                <h6>Size</h6>
                                <div className="slidepanel-inner">
                                  {sizeList && sizeList.map((item, index) => {
                                    return (
                                      <label className="slidepanel-label" key={index}>
                                        <input onClick={() => sizeFetch(item.size_id)} value={item.size_id} name="size" type="radio" className="label-checkbox" /> {item.size}
                                      </label>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            : ''
                        }
                        {filterBy && filterBy.length >= 1 ?
                          <div className="slidepanel-block">
                            <h6>Filter By</h6>
                            <div className="slidepanel-inner">
                              {filterBy && filterBy.map((item, index) => {
                                return (
                                  <>
                                    <small className="m-0" key={index}>{item.filter_master_name}</small>
                                    {
                                      item.values.map((item2, index2) => {
                                        return (
                                          <label className="slidepanel-label" key={index2}>
                                            <input type="radio" onClick={() => filterByFetch(item2.id)} name="filteby" value={item2.id} className="label-checkbox" /> {item2.name}
                                          </label>
                                        );
                                      })
                                    }
                                  </>
                                )
                              })}
                            </div>
                          </div>
                          : ''
                        }
                      </aside>
                      <div className="mainpanel position-relative">
                        {
                          loaderProduct && loaderProduct ? <div className="products-loader"> <Loader /> </div>
                            :
                            <>
                              {productList && productList.length > 0 ?
                                <>
                                  <div className="all-product">
                                    {/* All product */}
                                    {productList && productList.map((item, index) => {
                                      console.log(`/product/product-detail/${item.id}/${(item.name).replace(/\s+/g, '-')}`)
                                      return (
                                        <div key={index} className="section__product">
                                          <Link
                                            // to="/product/product-detail"
                                            // to={`/product/product-detail/${item.id}/${(item.name).replace(/\s+/g, '-')}` }
                                            to={`/product/product-detail/${item.id}/${(item.name).replace(/\s+/g, '-')}` }
                                            state ={item}
                                            > 
                                            <div className="product__image" >
                                              <img src={item.main_image} alt="" />
                                              <div className="product__actions">
                                                {/* <div className="product__item"><button><AiOutlineShopping /></button></div> */}
                                                {/* <div className="product__item"><button><AiOutlineHeart /></button></div> */}
                                              </div>
                                            </div>
                                          </Link>

                                          <div className="product__content">
                                            <Link
                                              to={`/product/product-detail/${item.id}-${(item.name).replace(/\s+/g, '-')}`}
                                              // to="/product/product-detail"
                                              state={item}>
                                              <div className="product__title" >{item.name}</div>
                                              <div className="product__meta">
                                                <span className="product__price sale">₹ {item.price}</span><span className="product__del">₹ {item.mrp}</span>
                                              </div>
                                            </Link>
                                          </div>
                                        </div>
                                      );
                                    })
                                    }
                                  </div>
                                  {/* Pagination */}
                                  <div className="pagination">
                                    {
                                      productList && productList.length > 0 ?
                                        <>
                                          <div className="col-md-12">
                                            <Pagination
                                              totalItemsCount={paginate && paginate.total_data}
                                              onChange={handlePageChange}
                                              activePage={paginate && paginate.current_page}
                                              currentPage={paginate && paginate.current_page}
                                              itemsCountPerPage={paginate && paginate.per_page}
                                              pageRangeDisplayed={10}
                                            />
                                          </div>
                                        </>
                                        : <></>
                                    }
                                  </div>
                                </>
                                :
                                <div style={{ textAlign: 'center' }}>
                                  <img src={require('../../assets/notFound.jpg')} className="noDataFound" alt="Not Found" />
                                  <p>No data Found</p>
                                </div>
                              }
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Outlet />
            </div>
          </>
      }
    </>
  );
}

export default ProductsGrid;
